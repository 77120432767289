import notHandledHere from './notHandledHere';
import { database } from 'lib_ui-services';

export default {
    verb: 'doingGet',
    excludedNamespaceRelations: notHandledHere,
    description: 'Obtain the requested data from the database',
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ context, data }) {
    let { type, namespace, relation } = context;
    const persistContext = { ...context, verb: 'persist', eventId: undefined };
    //if the type is:
    // 'find': payload should contain a raw mongodb syntax query in the root or
    // in the query field of the payload.
    // 'get': payload should contain { _id }
    const db = database.get();
    let result;
    switch (type) {
        case 'find': {
            //`data.query` would be preferred, but don't be picky...
            result = await db.find(data.query?.criteria ?? data.criteria ?? data.query ?? data, persistContext);
            const orderBy = data.query?.orderBy ?? data.orderBy;
            if (orderBy) {
                result.sort(orderBy);
            }
            const page = data.query?.page ?? data.page;
            if (page) {
                result = result.slice(page.offset || 0, page.limit);
            }
            break;
        }
        case 'get':
            result = await db.getById(data.query ?? data, persistContext);
            break;
        case 'fullTextSearch': {
            // Removed - in favor of doingGet_item_item.js
            // TODO: Remove this case when all calls are gone.
            throw new Error('Not supported - perform normal get.');
        }
        default: {
            // Fallback to get if _id is in the payload and no type is in the context
            if (data._id != null || data.query?._id != null) {
                result = await db.getById(data.query ?? data, persistContext);
                break;
            } else {
                throw new Error(`Unexpected payload and context values for 'get_${namespace}_${relation}_${type}'.`);
            }
        }
    }
    result = result.filter(x => !!x);
    return { ...data, result };
}
