import lokijs from 'lokijs';
import LokiIndexedAdapter from 'lokijs/src/loki-indexed-adapter';
import logging from '@sstdev/lib_logging';

/**
 * This fixes a problem where lokijs uses a shortcut when saving databases, causing rerenders and slow downs
 * in the application.
 *
 * More details:
 *  lokijs copies the database before saving it in order to remove (from the copy) stuff that
 *  cannot be serialized.  Unfortunately, it uses 'loadJSONObject' to do the copy and that is
 *  the same method it uses to rehydrate the database from disk (or wherever).  As part of loadJSONObject, loki
 *  'rematerializes' DynamicViews - meaning it rebuilds them.  So it is rebuilding DataViews on
 *  the copy of the database for no reason whatsoever.
 *  This code will only rebuild DynamicViews when loading to the database (not when copying).
 *
 * If this code looks really old-school, that's because it is.  I've just copied the prototypes for methods
 * out of the lokijs code and made the necessary changes.  If I modernize everything, it will be a pain to
 * integrate updates from lokijs in the future.
 */

/**
 * Inflates a loki database from a JS object
 *
 * @param {object} dbObject - a serialized loki database string
 * @param {object=} options - apply or override collection level settings
 * @param {bool} options.retainDirtyFlags - whether collection dirty flags will be preserved
 * @memberof Loki
 */
lokijs.prototype.loadJSONObject = function (dbObject, options = {}) {
    let i = 0,
        len = dbObject.collections ? dbObject.collections.length : 0,
        coll,
        copyColl,
        clen,
        j,
        loader,
        collObj;

    this.name = dbObject.name;

    // restore save throttled boolean only if not defined in options
    if (dbObject.hasOwnProperty('throttledSaves') && options && !options.hasOwnProperty('throttledSaves')) {
        this.throttledSaves = dbObject.throttledSaves;
    }

    this.collections = [];

    function makeLoader(coll) {
        let collOptions = options[coll.name];
        let inflater;

        if (collOptions.proto) {
            inflater = collOptions.inflate || copyProperties;

            return function (data) {
                let collObj = new collOptions.proto();
                inflater(data, collObj);
                return collObj;
            };
        }

        return collOptions.inflate;
    }

    for (i; i < len; i += 1) {
        coll = dbObject.collections[i];

        copyColl = this.addCollection(coll.name, {
            disableChangesApi: coll.disableChangesApi,
            disableDeltaChangesApi: coll.disableDeltaChangesApi,
            disableMeta: coll.disableMeta,
            disableFreeze: coll.hasOwnProperty('disableFreeze') ? coll.disableFreeze : true
        });

        copyColl.adaptiveBinaryIndices = coll.hasOwnProperty('adaptiveBinaryIndices')
            ? coll.adaptiveBinaryIndices === true
            : false;
        copyColl.transactional = coll.transactional;
        copyColl.asyncListeners = coll.asyncListeners;
        copyColl.cloneObjects = coll.cloneObjects;
        copyColl.cloneMethod = coll.cloneMethod || 'parse-stringify';
        copyColl.autoupdate = coll.autoupdate;
        copyColl.changes = coll.changes;
        copyColl.dirtyIds = coll.dirtyIds || [];

        if (options && options.retainDirtyFlags === true) {
            copyColl.dirty = coll.dirty;
        } else {
            copyColl.dirty = false;
        }

        // load each element individually
        clen = coll.data.length;
        j = 0;
        if (options && options.hasOwnProperty(coll.name)) {
            loader = makeLoader(coll);

            for (j; j < clen; j++) {
                collObj = loader(coll.data[j]);
                copyColl.data[j] = collObj;
                copyColl.addAutoUpdateObserver(collObj);
                if (!copyColl.disableFreeze) {
                    lokijs.deepFreeze(copyColl.data[j]);
                }
            }
        } else {
            for (j; j < clen; j++) {
                copyColl.data[j] = coll.data[j];
                copyColl.addAutoUpdateObserver(copyColl.data[j]);
                if (!copyColl.disableFreeze) {
                    lokijs.deepFreeze(copyColl.data[j]);
                }
            }
        }

        copyColl.maxId = typeof coll.maxId === 'undefined' ? 0 : coll.maxId;
        if (typeof coll.binaryIndices !== 'undefined') {
            copyColl.binaryIndices = coll.binaryIndices;
        }
        if (typeof coll.transforms !== 'undefined') {
            copyColl.transforms = coll.transforms;
        }

        // regenerate unique indexes
        copyColl.uniqueNames = [];
        if (coll.hasOwnProperty('uniqueNames')) {
            copyColl.uniqueNames = coll.uniqueNames;
        }

        // in case they are loading a database created before we added dynamic views, handle undefined
        if (typeof coll.DynamicViews === 'undefined') continue;

        // reinflate DynamicViews and attached Resultsets
        for (let idx = 0; idx < coll.DynamicViews.length; idx++) {
            let colldv = coll.DynamicViews[idx];

            let dv = copyColl.addDynamicView(colldv.name, colldv.options);
            dv.resultdata = colldv.resultdata;
            dv.resultsdirty = colldv.resultsdirty;
            dv.filterPipeline = colldv.filterPipeline;
            dv.sortCriteriaSimple = colldv.sortCriteriaSimple;
            dv.sortCriteria = colldv.sortCriteria;
            dv.sortFunction = null;
            dv.sortDirty = colldv.sortDirty;
            if (!copyColl.disableFreeze) {
                lokijs.deepFreeze(dv.filterPipeline);
                if (dv.sortCriteriaSimple) {
                    lokijs.deepFreeze(dv.sortCriteriaSimple);
                } else if (dv.sortCriteria) {
                    lokijs.deepFreeze(dv.sortCriteria);
                }
            }
            dv.resultset.filteredrows = colldv.resultset.filteredrows;
            dv.resultset.filterInitialized = colldv.resultset.filterInitialized;

            if (options.materializeViews) {
                dv.rematerialize({
                    removeWhereFilters: true
                });
            }
        }

        // Upgrade Logic for binary index refactoring at version 1.5
        if (dbObject.databaseVersion < 1.5) {
            // rebuild all indices
            copyColl.ensureAllIndexes(true);
            copyColl.dirty = true;
        }
    }
};
function copyProperties(src, dest) {
    let prop;
    for (prop in src) {
        dest[prop] = src[prop];
    }
}

/**
 * Loads a database which was partitioned into several key/value saves.
 * (Loki persistence adapter interface function)
 *
 * @param {string} dbname - name of the database (filename/keyname)
 * @param {function} callback - adapter callback to return load result to caller
 * @memberof LokiPartitioningAdapter
 */
lokijs.LokiPartitioningAdapter.prototype.loadDatabase = function (dbname, callback) {
    const self = this;
    this.dbname = dbname;
    this.dbref = new lokijs(dbname);
    this.failedPartitions = [];

    // load the db container (without data)
    this.adapter.loadDatabase(dbname, function (result) {
        // empty database condition is for inner adapter return null/undefined/falsy
        if (!result) {
            // partition 0 not found so new database, no need to try to load other partitions.
            // return same falsy result to loadDatabase to signify no database exists (yet)
            callback(result);
            return;
        }

        if (typeof result !== 'string') {
            callback(
                new Error('LokiPartitioningAdapter received an unexpected response from inner adapter loadDatabase()')
            );
        }

        // I will want to use loki destructuring helper methods so i will inflate into typed instance
        let db = JSON.parse(result);
        self.dbref.loadJSONObject(db, { materializeViews: true });
        db = null;

        if (self.dbref.collections.length === 0) {
            callback(self.dbref);
            return;
        }

        self.pageIterator = {
            collection: 0,
            pageIndex: 0
        };

        self.loadNextPartition(0, function (err) {
            if (err instanceof Error) {
                callback(err, self.dbref);
            }
            callback(self.dbref);
        });
    });
};

/**
 * Internal load logic, decoupled from throttling/contention logic
 *
 * @param {object} options - not currently used (remove or allow overrides?)
 * @param {function=} callback - (Optional) user supplied async callback / error handler
 */
lokijs.prototype.loadDatabaseInternal = function (options = {}, callback) {
    options.materializeViews = true;
    const cFun =
            callback ||
            function (err) {
                if (err) {
                    throw err;
                }
            },
        self = this;

    // the persistenceAdapter should be present if all is ok, but check to be sure.
    if (this.persistenceAdapter !== null) {
        this.persistenceAdapter.loadDatabase(this.filename, function loadDatabaseCallback(dbString) {
            if (typeof dbString === 'string') {
                let parseSuccess = false;
                try {
                    self.loadJSON(dbString, options || {});
                    parseSuccess = true;
                } catch (err) {
                    cFun(err);
                }
                if (parseSuccess) {
                    cFun(null);
                    self.emit('loaded', 'database ' + self.filename + ' loaded');
                }
            } else {
                // falsy result means new database
                if (!dbString) {
                    cFun(null);
                    self.emit('loaded', 'empty database ' + self.filename + ' loaded');
                    return;
                }

                // instanceof error means load faulted
                if (dbString instanceof Error) {
                    cFun(dbString);
                    return;
                }

                // if adapter has returned an js object (other than null or error) attempt to load from JSON object
                if (typeof dbString === 'object') {
                    self.loadJSONObject(dbString, options || {});
                    cFun(null); // return null on success
                    self.emit('loaded', 'database ' + self.filename + ' loaded');
                    return;
                }

                cFun('unexpected adapter response : ' + dbString);
            }
        });
    } else {
        cFun(new Error('persistenceAdapter not configured'));
    }
};

/* eslint-disable */
// I needed to override this prototype to fix a problem where partitions (i.e. files/db records
// containing collections) are somehow lost, but the main file (i.e. BBn where n is the
// monotonically increasing number for the tenant/usecase combination being replicated)
// thinks the collection is there.
// This code will report the failedPartitions
//
// Again, this code should look as identitical to the original as possible to allow easier
// maintenance/comparison.
/**
 * Used to sequentially load the next page of collection partition, one at a time.
 *
 * @param {function} callback - adapter callback to return load result to caller
 */
lokijs.LokiPartitioningAdapter.prototype.loadNextPage = function (callback) {
    // calculate name for next saved page in sequence
    var keyname = this.dbname + '.' + this.pageIterator.collection + '.' + this.pageIterator.pageIndex;
    var self = this;

    logging.debug(`[LOKIJS] - loading db page ${keyname}`);
    // load whatever page is next in sequence
    this.adapter.loadDatabase(keyname, function (result) {
        let data = [''];
        // If partition is lost capture the identity of the failed partition for handling
        // after the rest of the load is complete.
        if (result == null) {
            let collectionName = self.dbref.collections[self.pageIterator.collection].name;
            logging.error(
                `[LOKIJS] - failure while loading partition for collection ${collectionName} and page ${self.pageIterator.pageIndex}.  It looks like the database has been corrupted.`
            );
            self.failedPartitions.push({
                collectionName,
                partition: self.pageIterator.collection,
                page: self.pageIterator.pageIndex
            });
        } else {
            data = result.split(self.options.delimiter);
        }
        result = ''; // free up memory now that we have split it into array
        var dlen = data.length;
        var idx;

        // detect if last page by presence of final empty string element and remove it if so
        var isLastPage = data[dlen - 1] === '';
        if (isLastPage) {
            data.pop();
            dlen = data.length;
            // empty collections are just a delimiter meaning two blank items
            if (data[dlen - 1] === '' && dlen === 1) {
                data.pop();
                dlen = data.length;
            }
        }

        // convert stringified array elements to object instances and push to collection data
        for (idx = 0; idx < dlen; idx++) {
            self.dbref.collections[self.pageIterator.collection].data.push(JSON.parse(data[idx]));
            data[idx] = null;
        }
        data = [];

        // if last page, we are done with this partition
        if (isLastPage) {
            // if there are more partitions, kick off next partition load
            if (++self.pageIterator.collection < self.dbref.collections.length) {
                self.loadNextPartition(self.pageIterator.collection, callback);
            } else {
                callback();
            }
        } else {
            self.pageIterator.pageIndex++;
            self.loadNextPage(callback);
        }
    });
};

const originalSaveDatabase = LokiIndexedAdapter.prototype.saveDatabase;
LokiIndexedAdapter.prototype.saveDatabase = function (dbname, dbstring, callback) {
    if (this.catalog) {
        this.catalog.setAppKey = setErrorLoggingAppKey;
    }
    originalSaveDatabase.call(this, dbname, dbstring, callback);
};

function setErrorLoggingAppKey(app, key, val, callback) {
    var transaction = this.db.transaction(['LokiAKV'], 'readwrite');
    var store = transaction.objectStore('LokiAKV');
    var index = store.index('appkey');
    var appkey = app + ',' + key;
    var request = index.get(appkey);

    // first try to retrieve an existing object by that key
    // need to do this because to update an object you need to have id in object, otherwise it will append id with new autocounter and clash the unique index appkey
    request.onsuccess = function (e) {
        var res = e.target.result;

        if (res === null || res === undefined) {
            res = {
                app: app,
                key: key,
                appkey: app + ',' + key,
                val: val
            };
        } else {
            res.val = val;
        }

        var requestPut = store.put(res);

        requestPut.onerror = (function (usercallback) {
            return function (e) {
                if (typeof usercallback === 'function') {
                    //next 3 lines added to give more info in case of error
                    console.error('LokiCatalog.setAppKey (set) put onerror');
                    console.error(e);
                    console.error(request.error);
                    usercallback({ success: false });
                } else {
                    console.error('LokiCatalog.setAppKey (set) put onerror');
                    console.error(request.error);
                }
            };
        })(callback);

        requestPut.onsuccess = (function (usercallback) {
            return function (e) {
                if (typeof usercallback === 'function') {
                    usercallback({ success: true });
                }
            };
        })(callback);
    };

    request.onerror = (function (usercallback) {
        return function (e) {
            if (typeof usercallback === 'function') {
                //next 3 lines added to give more info in case of error
                console.error('LokiCatalog.setAppKey (set) onerror');
                console.error(e);
                console.error(request.error);
                usercallback({ success: false });
            } else {
                console.error('LokiCatalog.setAppKey (get) onerror');
                console.error(request.error);
            }
        };
    })(callback);
}

/* eslint-enable */
