//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _doingValidate_identity_role from './doingValidate_identity_role';
import _willRemove_identity_role from './willRemove_identity_role';
export const doingValidate_identity_role = _doingValidate_identity_role;
export const willRemove_identity_role = _willRemove_identity_role;
export default {doingValidate_identity_role, willRemove_identity_role};

