import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { default as DropDown } from '../_abstractComponent/DropDown';
import CriteriaElement from './CriteriaElement';
import createTreePositionGetter from '../../utilities/createTreePositionGetter';

import operations from './criteriaOperations';

const supportedOperations = [operations.EQUALS, operations.EQUALSNOT];
// Avoids warning about switching between 'controlled' and 'uncontrolled' values.
const defaultValue = { title: '', isDefaultRecord: true };
const _p = {
    DropDown
};
export const _private = _p;
export default function DropDownCriteria(props) {
    const {
        hNode: { id, foreignNamespace, foreignRelation, treePosition }
    } = props || { hNode: {} };

    const getTreePosition = createTreePositionGetter(treePosition, 2);
    return rc(
        CriteriaElement,
        {
            ...props,
            hNode: {
                ...props.hNode,
                foreignNamespace: undefined,
                foreignRelation: undefined,
                propertyName: `${foreignNamespace}:${foreignRelation}`,
                displayUnassignedRow: false,
                treePosition: getTreePosition(0)
            },
            supportedOperations,
            defaultValue
        },
        rc(_p.DropDown, { ...props, id: `innerValue${id}`, treePosition: getTreePosition(1) })
    );
}

DropDownCriteria.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};
