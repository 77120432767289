import defaultWillRemove from '../../namespace/relation/willRemove_namespace_relation';

export default {
    verb: 'willRemove',
    namespace: 'identity',
    relation: 'role',
    prerequisites: [],
    priority: defaultWillRemove.priority + 10, //higher priority than (run before) default
    description: 'Prevent deletion of standard/system roles',
    //this is the actual logic:
    logic: willRemove
};

function willRemove(message) {
    if (message.data.skipConfirm) return message;
    const { system, title } = message.data.record;
    if (system) {
        throw new Error(`Unable to delete built-in role ${title}.`);
    }
    return message;
}
