import { http, metadata } from 'lib_ui-services';
import pThrottle from 'p-throttle';

const _p = { pThrottle };
export const _private = _p;

// We are rate limited to 50 requests per path per minute
const MAX_REQUEST_PER_TIME_FRAME = 50;
const REQUEST_RATE_TIME_FRAME = 60000; // 1 minute in ms

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

const BATCH_SIZE = 100;

export default {
    verb: 'doingCreate',
    namespace: 'import',
    relation: 'import',
    description: 'Doing the actual import, by sending the data over to the server',
    // this is the actual logic:
    logic: doingCreate,
    // error handling to avoid the progress staying on the screen:
    onError
};

/**
 * @param {{
 *      error: Error;
 *      data: T;
 *      context: Context;
 *      dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>,
 *      workflowStack: WorkflowStack[]
 * }} parameters
 * */
function onError({ error, dispatch }) {
    dispatch(
        {
            mainTitle: 'Importing'
        },
        { verb: 'reset', namespace: 'application', relation: 'progress' }
    );
    throw error;
}

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingCreate({ data, dispatch, log }) {
    const {
        newRecord: { _id, title, data: parsedData, fileSize, foreignNamespace, foreignRelation, ...importDetails }
    } = data;
    const url = `/api/${foreignNamespace}/${foreignRelation}/${_id}/import`;

    // calculate the average chunk size
    const totalRecords = parsedData.length;
    const averageRecordSize = fileSize / totalRecords;

    log.info(`Importing ${totalRecords} into ${foreignNamespace}:${foreignRelation}.`);
    if (totalRecords === 0) return;

    const titleAlternative = metadata.getTitleAlternative(foreignNamespace, foreignRelation, 'title');

    // throttle at 1 per part of interval, rather than MAX_REQUEST_PER_TIME_FRAME as limit and REQUEST_RATE_TIME_FRAME as interval
    // to avoid an initial surge and avoid any risk of hitting rate limits:
    const throttle = _p.pThrottle({
        limit: 1,
        interval: REQUEST_RATE_TIME_FRAME / MAX_REQUEST_PER_TIME_FRAME
    });
    const postBatch = throttle(batch => http.post(url, batch));
    const failed = [];
    for (let chunkIndex = 0; chunkIndex * BATCH_SIZE < totalRecords; chunkIndex++) {
        const chunk = parsedData.slice(chunkIndex * BATCH_SIZE, (chunkIndex + 1) * BATCH_SIZE);
        const chunkSize = Math.round(chunk.length * averageRecordSize);
        const progress = Math.round((chunkIndex * BATCH_SIZE + chunk.length) * averageRecordSize);
        const batch = {
            _id,
            title,
            namespace: foreignNamespace,
            relation: foreignRelation,
            ...importDetails,
            data: chunk,
            fileSize,
            totalRecords,
            progress,
            // the server uses chunk size rather than progress,
            // as theoretically there is a chance that it might process these batches in parallel/out of order
            // chunkSize will allow it to simply add the current value to whatever was stored (rather than replace it)
            chunkSize
        };
        const result = await postBatch(batch);
        if (result?.[0]?.failed?.length) {
            // include more detail about any failed import records so it be used later to show the user
            result?.[0]?.failed?.forEach(f => {
                failed.push({
                    ...f,
                    title: f[titleAlternative]
                });
            });
        }
        await dispatch(
            {
                mainTitle: 'Importing',
                title,
                current: chunkIndex + 1,
                total: totalRecords / 100
            },
            { verb: 'update', namespace: 'application', relation: 'progress' }
        );
    }

    return {
        ...data,
        result: {
            title,
            totalRecords,
            failed
        }
    };
}
