import { createElement as rc, memo } from 'react';
import { View, h3, List, fromTheme, styled, Pressable, webOnlyStyles } from 'lib_ui-primitives';

let StyledGrid = styled(View).attrs({ name: 'StyledGrid' })`
    border: none;
    flex-grow: 1;
    flex-direction: column;
`;

StyledGrid = webOnlyStyles(StyledGrid)`
    ${({ style }) => (style?.minWidth ? `min-width: ${style.minWidth}px;` : '')};
`;

const GridTitle = styled(h3)`
    margin: 6px;
    color: ${({ theme }) => theme.defaultFontColor};
`;

const GridBody = styled(View).attrs({ name: 'GridBody' })`
    min-height: 100px;
    flex-grow: 1;
    flex-direction: row;
`;

const Row = styled(Pressable)`
    text-align: center;
    height: ${fromTheme('listLineHeight')};
    line-height: ${fromTheme('listLineHeight')};
    border-color: transparent;
    border-style: solid;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0px;
    border-bottom-color: ${fromTheme('gridLineColor')};
`;

function Grid(props) {
    const {
        hNode,
        hNode: { title, id },
        listType,
        records,
        recordCount,
        sortedColumns,
        columnHNodes,
        headerClicked,
        onClick,
        onRenderItem,
        className,
        style,
        RowDetail,
        HeaderRow,
        onScrollLayoutChange,
        endOfRecordsText
    } = props || { hNode: {} };

    // prettier-ignore
    return rc(StyledGrid, { id: 'grid' + id, name: 'grid', className, style, 'data-test-count': recordCount },
        title && title.length ? rc(GridTitle, { name: 'grid-title' }, title) : null,
        rc(GridBody, { id: `grid-body-${id}`, name: 'grid-body' },
            rc(List, { id, onScrollLayoutChange, data: records, itemCount: recordCount, onClick, onRenderItem, Row, RowDetail, listType, endOfRecordsText },
                rc(HeaderRow, { hNode, headerClicked, sortedColumns, columnHNodes })
            )
        )

    );
}
const _Grid = memo(Grid);
_Grid.displayName = 'MemoizedGrid';
export default _Grid;
