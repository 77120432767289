import Grid from '../Grid';
import { createElement as rc, useState, useCallback, useContext, Fragment, useMemo } from 'react';
import lodash from 'lodash';
const { omit } = lodash;
import { h5, View, useDebounce, useIsAnimating } from 'lib_ui-primitives';
import { ObjectId } from 'lib_ui-services';
import useDbView from '../../../hooks/useDbView';
import useEventSink from '../../../hooks/useEventSink';
import useRowDetail from './useRowDetail';
import HeaderRow from './HeaderRow';
import { ThemeContext } from 'styled-components';
import useColumnCustomization from '../useColumnCustomization';
import useMultiSelect from '../../../hooks/useMultiSelect';

const _p = {
    useDbView,
    useDebounce,
    // eslint-disable-next-line no-undef
    isNative: () => __SST_REACT_NATIVE__ || __TEST_RFID__
};
export const _private = _p;

function TableList(props) {
    const {
        hNode,
        hNode: {
            namespace,
            relation,
            id,
            rowClickAction = 'edit',
            includeCheckbox,
            singleRowSelect,
            endOfRecordsText = 'End of Records'
        },
        ...otherProps
    } = props || { hNode: {} };
    const [subscribe, publish] = useEventSink();
    const theme = useContext(ThemeContext);
    const { onIndividualChange } = useMultiSelect({ namespace, relation, singleRowSelect });

    const {
        records: _records,
        recordCount: _recordCount,
        viewCriteria,
        viewReady,
        requiresPostProcessing,
        calculateAggregateResult
    } = _p.useDbView(namespace, relation, undefined, hNode);

    const [records, recordCount] = useMemo(() => {
        if (requiresPostProcessing) {
            const results = calculateAggregateResult(_records, _recordCount);
            return [results || [], results?.length || 0];
        }
        return [_records, _recordCount];
    }, [requiresPostProcessing, _records, _recordCount, calculateAggregateResult]);

    //keep track of which columns are asc/desc sorted
    const [sortedColumns, setSortedColumns] = useState({});
    const headerClicked = useCallback(
        function (column) {
            if (column.sortable) {
                setSortedColumns(prev => {
                    let nextValue = 'asc';
                    if (prev[column.id] === 'asc') {
                        nextValue = 'desc';
                    }
                    const path = `${column.propertyPath ? column.propertyPath + '.' : ''}${column.propertyName}`;
                    viewCriteria.addSort(path, nextValue === 'desc', `columnSort_${id}`);
                    return { ...prev, [column.id]: nextValue };
                });
            }
        },
        [viewCriteria, id]
    );

    // avoids rerenders of List
    const onClick = _p.useDebounce(
        item => {
            if (rowClickAction === 'nothing') return;
            if (singleRowSelect && includeCheckbox) {
                onIndividualChange(item._id, true);
            }
            if (item.inDatabase === false) {
                // unknown reads cannot be fetched from the database
                // we need to pass in what we have
                // there is only a "rowClickAction". Should we conform to that?
                // or should we always send a "new" action?
                //leaving in commented out code in case we change our mind:
                let data = { _id: item._id };
                // switch (rowClickAction) {
                //     case 'new':
                data.newRecord = omit(item, ['_id', 'name', 'rssi', 'sensorType', 'time']);
                //         break;
                //     case 'edit':
                //     default:
                //         data.record = item;
                //         break;
                // }
                publish(data, { verb: 'new', namespace, relation });
            } else {
                // for anything else, we want to make sure we fetch the latest form the database, so only pass in the _id
                publish({ _id: item._id }, { verb: rowClickAction, namespace, relation });
            }
        },
        [publish, namespace, relation, rowClickAction],
        350,
        {}
    );

    //used in lib_ui-primitives\src\components\List\RenderNotifyingRow.js (Which is never used...)
    const onRenderItem = useCallback(
        ({ item, updateItem, signal }) => {
            let aborted = false;
            const eventId = new ObjectId().toString();

            const unsubscribe = subscribe({ verb: 'didGet', namespace, relation, status: 'success' }, payload => {
                const {
                    result: [updatedItem]
                } = payload;

                if (item._id === updatedItem._id) {
                    if (!aborted) {
                        updateItem(updatedItem);
                    }
                }
            });
            signal.addEventListener('abort', () => {
                aborted = true;
                //unsubscribe event listeners here.
                unsubscribe();
            });
            publish({ result: [item] }, { verb: 'didGet', namespace, relation, eventId });
        },
        [subscribe, publish, namespace, relation]
    );

    // Avoids rerenders since this would otherwise create a new object reference every time.
    const style = useMemo(() => {
        return _p.isNative()
            ? {}
            : {
                  //always show at least the first column, AND the checkbox if there is one.
                  minWidth: `${includeCheckbox ? theme.maxTableColumnWidth + 42 : theme.maxTableColumnWidth}px`
              };
    }, [includeCheckbox, theme.maxTableColumnWidth]);

    const [columnHNodes, configurationPortal, configurationReady] = useColumnCustomization(props);
    const [RowDetail, onScrollLayoutChange] = useRowDetail(hNode, columnHNodes, onClick, endOfRecordsText);
    // jsDom does not have a layout engine, so onScrollLayoutChange needs to be set manually during testing
    _p.onScrollLayoutChange = onScrollLayoutChange;
    const isAnimating = useIsAnimating();
    if (!viewReady || isAnimating || !configurationReady) {
        return rc(View, null, rc(h5, null, 'Loading...'));
    }
    return rc(
        Fragment,
        null,
        configurationPortal,
        rc(Grid, {
            hNode,
            listType: 'table',
            records,
            recordCount,
            sortedColumns,
            columnHNodes,
            headerClicked,
            onClick,
            onRenderItem,
            RowDetail,
            HeaderRow,
            onScrollLayoutChange,
            style,
            endOfRecordsText,
            ...otherProps
        })
    );
}
export default TableList;
