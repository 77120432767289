import { createElement as rc, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { View, Text, Icon, CheckBox, fromTheme, webOnlyStyles, styled } from 'lib_ui-primitives';
import getColumnWidth from '../columns/getColumnWidth';
import useMultiSelect from '../../../hooks/useMultiSelect';
import getNestedPatchDetailColumnHNodes from '../columns/PatchDetail/getNestedPatchDetailColumnHNodes';
import useDataModel from '../../../hooks/useDataModel';

let TableHeader = styled(View).attrs({ name: 'Header' })`
    background-color: ${fromTheme('colorScheme', 'background-dark')};
    display: flex;
    flex-direction: row;
    border-top-width: 1px;
    border-top-color: ${fromTheme('borderColor')};
    flex-shrink: 0;
    min-width: 100%;
`;
TableHeader = webOnlyStyles(TableHeader)`
    border-top-style: solid;
    box-shadow: ${({ theme }) => `${theme.borderColor} 0 1px 2px`};
    width: fit-content;
`;

let TableHeaderColumn = styled(View)`
    display: flex;
    flex-direction: row;
    min-width: ${({ width }) => width + 'px'};
    justify-content: flex-start;
    align-items: center;
    border-left-width: 0;
    border-left-color: white;
`;
TableHeaderColumn = webOnlyStyles(TableHeaderColumn)`
    width: fit-content;
    border-left-style: solid;
`;

let FirstCell = styled(Text)`
    color: ${fromTheme('colorScheme', 'white-text')};
    padding: ${fromTheme('textPadding')};
    margin-left: ${fromTheme('viewMargin')};
    text-align: left;
`;
FirstCell = webOnlyStyles(FirstCell)`
    cursor: ${({ sortable }) => (sortable ? 'pointer' : 'inherit')};
`;

const CheckBoxFirstCell = styled(View)`
    align-items: center;
    padding: 0 ${fromTheme('viewPaddingMore')} 0 ${fromTheme('viewPadding')};
`;
// Add breaks between cells by increasing border-left-width:
// border-left-width: 1px;
// border-left-color: black;
let NotFirstCell = styled(Text)`
    color: ${fromTheme('colorScheme', 'white-text')};
    text-align: left;
    padding: ${fromTheme('textPadding')};
`;
NotFirstCell = webOnlyStyles(NotFirstCell)`
    cursor: ${({ sortable }) => (sortable ? 'pointer' : 'inherit')};
`;

const ArrowIcon = styled(Icon)`
    font-size: 20px;
`;

const _p = {
    useDataModel
};
export const _private = _p;

export default function HeaderRow(props) {
    const {
        hNode,
        hNode: { includeCheckbox, singleRowSelect, namespace, relation },
        headerClicked,
        sortedColumns,
        columnHNodes: _columnHNodes
    } = props || { hNode: {} };
    const theme = useContext(ThemeContext);
    let columnHNodes = (_columnHNodes || hNode.children.filter(c => c.hNodeTypeGroup === 'listColumn')).filter(
        c => !c.hidden
    );

    const widths = columnHNodes.map(hNode => getColumnWidth(hNode, theme));

    const { onChangeEverything, invertSelection } = useMultiSelect({ namespace, relation, singleRowSelect });
    const dataDictionary = _p.useDataModel(namespace, relation);

    // prettier-ignore
    return rc(TableHeader, null,
        includeCheckbox && rc(CheckBoxFirstCell, {},
           !singleRowSelect && rc(CheckBox, {
                onClick: onChangeEverything,
                value: invertSelection,
                fontColor: theme.colorScheme['white-text'],
                checkedColor: theme.colorScheme['white-text']
            })
        ),
        columnHNodes.flatMap((c, i) => {
            let columns = [c];
            if (c.hNodeType === 'PatchDetail') {
                columns = getNestedPatchDetailColumnHNodes(c.propertyName);
            }
            // most of the time this will only return 1 column. just for PatchDetail this will return 4 columns
            return columns.map((c, j) => {
                if (c.hNodeType === 'NestedDetail') {
                    //TODO: use data dictionary to see if this property is array or object
                    let needsArrayIndex = false;
                    if (dataDictionary[c.propertyName]) {
                        const meta = dataDictionary[c.propertyName]._meta;
                        needsArrayIndex = meta.hNodeType === 'EditableList' || meta.overrideDataType === 'array';
                        needsArrayIndex = needsArrayIndex && !c.propertyName.match(/\.\d$/);
                    }
                    let child = c.children[0];
                    //sorting does not work with `[0]`!
                    child.propertyPath = c.propertyName + (needsArrayIndex ? '.0' : '');
                    c = child;
                }
                return rc(TableHeaderColumn, { width: c.width || widths[i], key: 'headerColumn' + i + j},
                    c.sortable && sortedColumns[c.id] === 'asc' ? rc(ArrowIcon, { title: 'Ascending' }, 'arrow_upward') : null,
                    c.sortable && sortedColumns[c.id] === 'desc' ? rc(ArrowIcon, { title: 'Descending' }, 'arrow_downward') : null,
                    i === 0 && !includeCheckbox
                        ? rc(FirstCell, { onClick: () => headerClicked(c), sortable: c.sortable === 'true' }, c.label)
                        : rc(NotFirstCell, { onClick: () => headerClicked(c), sortable: c.sortable === 'true' }, c.label)
                );
            });
        })
    );
}
