import { useRef, useCallback, useEffect } from 'react';
let globalChangeObservers = []; // store callbacks for global change events subscriptions.
// Often components need a very simple change observer.
// The eventSink would be overkill and this cleans up everything neatly on unmounts, etc.
export default function useSimpleChangeObserver(global = false) {
    const changeObservers = useRef(global ? globalChangeObservers : []); // store callbacks for change events subscriptions.
    let isUnmounted = useRef(false);
    useEffect(() => () => (isUnmounted.current = true), []);
    const onChange = useCallback(function onChange(func) {
        if (typeof func !== 'function') {
            throw new Error('useSimpleChangeObserver.onChange must be passed a function.');
        }
        changeObservers.current.push(func);
        // unsubscribe
        return () => {
            changeObservers.current.splice(changeObservers.current.indexOf(func), 1);
        };
    }, []);

    const publishChange = useCallback(function publishChange(type, entry) {
        changeObservers.current.forEach(func => {
            if (isUnmounted.current) return;
            func(type, entry);
        });
    }, []);

    return { onChange, publishChange };
}
