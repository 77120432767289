import { createElement as rc, cloneElement, useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled, h3, ValidationError, View, fromTheme } from 'lib_ui-primitives';
import { default as DropDown } from '../_abstractComponent/DropDown';
import useFormControl from '../../hooks/useFormControl';
import operations from './criteriaOperations';
import lodash from 'lodash';
const { isEqual } = lodash;

const CriteriaElementContainer = styled(View).attrs({ name: 'criteria-element' })`
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    padding: ${fromTheme('viewMargin')};
    padding-bottom: ${fromTheme('viewPaddingMore')};
    margin-bottom: ${fromTheme('viewMargin')};
    background-color: ${fromTheme('backgroundColorLighter')};
`;
CriteriaElementContainer.displayName = 'CriteriaElement';

const _p = {
    DropDown,
    useFormControl
};
export const _private = _p;

const EMPTY_OBJECT = {};
function CriteriaElement(props) {
    const {
        hNode: { id, treePosition },
        supportedOperations = [operations.EQUALS, operations.EQUALSNOT],
        defaultValue,
        children
    } = props || { hNode: {} };

    const defaultOp = supportedOperations[0];
    const [opValue, setOpValue] = useState(defaultOp);
    const [innerValue, setInnerValue] = useState(defaultValue);

    const {
        autoFocus,
        value: combinedValue,
        setValue: setCombinedValue,
        onBlur,
        onFocus,
        defaultReady,
        disabled,
        errors,
        active
    } = _p.useFormControl({ ...props, defaultValue: EMPTY_OBJECT });

    const createNewCombinedValue = useCallback(
        function createNewCombinedValue(newOp, newInnerValue) {
            if (newOp != null) {
                if (isEqual(newOp, opValue)) return;
                setOpValue(newOp);
            } else {
                newOp = opValue;
            }
            if (newInnerValue != null) {
                if (typeof newInnerValue === 'object' && newInnerValue.title) {
                    // quick and (somewhat?) dirty fix.
                    // CriteriaElement probably should receive a "formatInnerValue()" function or something like that
                    // so that the dropdown can implement logic to pull all the proper fields (not just "title") based on its configuration.
                    // alternatively, it might be the case that the setValue as it is implemented needs to be re-evaluated,
                    // as perhaps IT should already clean up the result before ever sending it over?
                    // Then again, the backend seems to only be matching on _id and title.... so this might be enough permanently
                    // alternatively, we might need to fix the backend to just look for _ids?

                    const { _id, title } = newInnerValue;
                    newInnerValue = { _id, title };
                }
                if (isEqual(newInnerValue, innerValue)) return;
                setInnerValue(newInnerValue);
            } else {
                newInnerValue = innerValue;
            }
            const newCombinedValue = {
                [newOp._id]: newInnerValue
            };
            setCombinedValue(prev => (isEqual(prev, newCombinedValue) ? prev : newCombinedValue));
        },
        [innerValue, opValue, setCombinedValue]
    );

    useEffect(() => {
        if (combinedValue && Object.keys(combinedValue) && !isEqual(combinedValue, EMPTY_OBJECT)) {
            const newOpId = Object.keys(combinedValue)[0];
            const newOp = operations.find(newOpId);
            const newValue = combinedValue[newOpId];
            setInnerValue(prev => (isEqual(prev, newValue) ? prev : newValue));
            setOpValue(prev => (isEqual(prev, newOp) ? prev : newOp));
        }
    }, [setInnerValue, setOpValue, combinedValue]);

    //We are letting React render the nested elements first, so we don't need any logic (or references) to that
    //then, we clone, inject with our propertyPath, memoize and replace those elements.
    //(The memoization is so that on subsequent renders, our cloned and updated element is directly rendered.)
    const subControls = useMemo(
        () =>
            [].concat(children).map(element => {
                return cloneElement(element, {
                    ...element.props,
                    autoFocus,
                    disabled,
                    value: innerValue,
                    required: true,
                    //when coming from an input field like shortText of integer:
                    onChange: x => createNewCombinedValue(undefined, x),
                    //when coming from a dropdown related component
                    setValue: x => createNewCombinedValue(undefined, x),
                    onBlur,
                    onFocus,
                    active
                });
            }),
        [autoFocus, children, createNewCombinedValue, disabled, innerValue, onBlur, onFocus, active]
    );

    const opProps = useMemo(
        () => ({
            hNode: {
                id: `op-${id}`,
                displayUnassignedRow: false,
                treePosition
            },
            autoFocus,
            required: true,
            disabled,
            setValue: x => createNewCombinedValue(x),
            value: opValue,
            onBlur,
            onFocus,
            records: supportedOperations,
            active
        }),
        [
            autoFocus,
            disabled,
            id,
            onBlur,
            onFocus,
            opValue,
            supportedOperations,
            treePosition,
            createNewCombinedValue,
            active
        ]
    );

    if (!defaultReady) {
        return rc(h3, null, 'Loading...');
    }

    return rc(
        CriteriaElementContainer,
        { id },
        rc(_p.DropDown, opProps),
        ...subControls,
        rc(ValidationError, { errors })
    );
}

CriteriaElement.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired,
    supportedOperations: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string,
            title: PropTypes.string,
            symbol: PropTypes.string
        })
    ),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired
};
export default CriteriaElement;
