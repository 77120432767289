import { ObjectId, offlineResilientCommunication } from 'lib_ui-services';

export default {
    verb: 'doingBulk',
    description: 'fan out the selection of a mass delete operation to do all actual removes',
    type: 'remove',
    //this is the actual logic:
    logic: doingBulkRemove
};

const _p = {
    getOfflineAction: offlineResilientCommunication.getOfflineAction
};
export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: {selection:Array<{_id:string}>};
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingBulkRemove({ data, dispatch, context }) {
    if (!data?.selection?.length) return;
    const { type, namespace, relation } = context;

    const currentTime = new Date().toISOString();
    const currentUser = { _id: context.user._id, displayName: context.user.displayName };

    const meta = { deleted: true, deletedBy: currentUser, deletedTime: currentTime };
    // kick of an individual remove here for each record included in the selection
    // Can we? Or do we need to implement a bulk remove operation on LokiJS,
    // and possibly even a means of getting that across to the server in bulk fashion?
    data.selection.map(({ _id: id }) => {
        const singleContext = { ...context, correlationId: ObjectId() };
        const offlineAction = _p.getOfflineAction(
            meta,
            singleContext.correlationId,
            id,
            { id },
            { ...singleContext, verb: type }
        );
        dispatch(
            {
                id, //databaseOperations/remove.js expects an `id` instead of `_id`
                //title, //not really used anywhere?
                meta,
                offlineAction,
                skipConfirm: true
            },
            { verb: type, namespace, relation }
        );
    });
}
