import { metadata } from 'lib_ui-services';
import { COMMON_COLOR_SCHEME } from 'lib_ui-primitives';
import pluralize from 'pluralize';

export default {
    verb: 'willBulk',
    excludedNamespaceRelations: [{ namespace: 'file' }],
    description: 'Confirm user REALLY wants to delete the whole batch',
    type: 'remove',

    //this is the actual logic:
    logic: willBulkRemove
};

const _p = {
    metadata
};
export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willBulkRemove({ data, dispatch, context }) {
    //if the data already has a meta object on the root, we don't need to do anything, just pass through
    //most likely came from sockets
    if (data.skipConfirm) return;
    const { namespace, relation } = context;
    const prettyName = _p.metadata.getPrettyRelationName(namespace, relation);

    if (!data?.selection?.length) {
        const message = `Please select at least 1 ${prettyName} to delete.`;
        dispatch(
            {
                message,
                timeout: 5000,
                addToList: true,
                isError: true
            },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
        throw new Error(message);
    }
    const pluralName = pluralize(prettyName, data.selection.length);
    const plural1000 = pluralize(prettyName, 1000);

    if (data.selection.length > 1000) {
        const message = `You selected ${data.selection.length} ${pluralName}. Unable to remove more than 1000 ${plural1000} at a time.`;
        dispatch(
            {
                message,
                timeout: 5000,
                addToList: true,
                isError: true
            },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
        throw new Error(message);
    }

    const userConfirmed = await new Promise(resolve => {
        let message = [`Are you sure you wish to delete ${data.selection.length} ${pluralName}?`];
        if (data.selection.length < 10) {
            const titleAlternative = _p.metadata.getTitleAlternative(namespace, relation);
            const details = replaceLast(
                `You are about to delete ${pluralName} ${data.selection.map(x => x[titleAlternative]).join(', ')}.`,
                ', ',
                ' and '
            );
            message.unshift(details);
        }
        dispatch(
            {
                message,
                icon: 'warning',
                iconColor: COMMON_COLOR_SCHEME.warn,
                okAction: () => {
                    resolve(true);
                },
                cancelAction: () => {
                    resolve(false);
                }
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    });

    if (userConfirmed) {
        return data;
    } else {
        // don't throw an error, as that pollutes the console and sentry
        // but simply leave nothing in the selection to be removed
        return { ...data, selection: [] };
    }
}

function replaceLast(string, match, replacement) {
    let pcs = string.split(match);
    let lastPc = pcs.pop();
    if (!pcs.length) {
        return string;
    }
    return pcs.join(match) + replacement + lastPc;
}
