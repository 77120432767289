import { createElement as rc, useState, useCallback, useEffect, useMemo } from 'react';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { Button, hooks } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
import lodash from 'lodash';
const { cloneDeep } = lodash;
const { omit } = lodash;
import useEventSink from '../../../hooks/useEventSink';
import {
    ConfigurationPortal,
    ConfigurationPortalContainer,
    scrollContainer,
    OrderableList,
    ButtonBar,
    Title
} from './styles';
import { DraggableRow } from './DraggableRow';
const { isEqual } = lodash;

const { useBbState, useRouter } = hooks;

const _p = {
    DraggableRow,
    useRouter,
    useBbState,
    getCustomizationStateName
};
export const _private = _p;
export default function useColumnCustomization(props) {
    const {
        hNode,
        hNode: { namespace, relation, children, allowCustomization = true }
    } = props ?? { hNode: {} };
    const title = 'Customize Column Order & Visibility';
    const id = hNode?.id ?? props.id;
    const router = _p.useRouter();
    const [subscribe, publish] = useEventSink();

    const [portalOpen, setPortalOpen] = useState(false);
    const hNodeColumns = useMemo(() => children.filter(c => c.hNodeTypeGroup === 'listColumn'), [children]);

    const [gridColumns, setGridColumns] = useState([]);
    const [currentConfiguration, setCurrentConfiguration] = useState([]);

    // === Column Initialization ===
    //NOTE: Every time we call setStoredColumnHNodes, bbStateReady will momentarily toggle to false, and back to true
    //So, avoid calling setStoredColumnHNodes, unless you know you have a change.
    const [storedColumnHNodes, setStoredColumnHNodes, bbStateReady] = _p.useBbState(
        hNodeColumns.map(c => hNodeToEntry(c)),
        // using the pathname as (part of) the stateName plus `SCOPE.GLOBAL`
        // allows for any navigation selection page, to use the same columns as all the other.
        // e.g.   `Take>inventory May`  and   `Take>Inventory June`   will get the same column layout.
        _p.getCustomizationStateName(router.location.pathname, namespace, relation),
        {
            duration: constants.retention.DURATION.ALWAYS,
            scope: constants.retention.SCOPE.GLOBAL,
            visibility: constants.retention.VISIBILITY.PER_BROWSER,
            // The hNode ID is not used, as the scope is set to global
            id
        }
    );

    //this keeps the returned columns in sync with the stored state
    useEffect(() => {
        if (!bbStateReady) return;
        let configChanged = false;
        //first, remove or update any old information
        let newConfig = storedColumnHNodes
            .map(({ id, title, hidden }) => {
                const hc = hNodeColumns.find(hc => hc.id === id);
                if (!hc) {
                    configChanged = true;
                    return null;
                }
                if (title !== hc.title) {
                    configChanged = true;
                    return hNodeToEntry({ ...hc, hidden });
                }
                return { id, title, hidden };
            })
            .filter(x => !!x);
        //now add any new columns (at the end)
        hNodeColumns.forEach(hc => {
            if (!newConfig.find(c => c.id === hc.id)) {
                configChanged = true;
                newConfig.push(hNodeToEntry(hc));
            }
        });
        //ONLY call setStoredColumnHNodes if the value actually changed.
        if (configChanged) setStoredColumnHNodes(newConfig);

        //calculate the resulting columns to display:
        let result = newConfig
            .map(storedColumn => {
                if (storedColumn.hidden) return null;
                return omit(
                    hNodeColumns.find(hc => hc.id == storedColumn.id),
                    'hidden'
                );
            })
            .filter(x => !!x);
        setGridColumns(prev => {
            //prevent unnecessary renders
            if (isEqual(prev, result)) {
                return prev;
            }
            return result;
        });
    }, [bbStateReady, hNodeColumns, setStoredColumnHNodes, storedColumnHNodes]);

    // === Core Logic ===
    const toggleVisibility = useCallback(
        index =>
            //inside a setTimeout with 0 to prevent the cannot update state from a child component error
            //which happens despite this being called from an onClick handler
            setTimeout(() => {
                setCurrentConfiguration(prev => {
                    prev[index] = { ...prev[index], hidden: !prev[index].hidden };
                    return [...prev];
                });
            }, 0),
        []
    );
    const moveEntry = useCallback(
        (dragId, dropId) =>
            //inside a setTimeout with 0 to prevent the cannot update state from a child component error
            //which happens despite this being called from an onClick handler
            setTimeout(() => {
                setCurrentConfiguration(prev => {
                    const originalIndex = prev.findIndex(p => p.id === dragId);
                    const [movingEntry] = prev.splice(originalIndex, 1);
                    const targetIndex = prev.findIndex(p => p.id === dropId);
                    prev.splice(targetIndex, 0, movingEntry);
                    return [...prev];
                });
            }, 0),
        []
    );

    const onResetClick = useCallback(() => {
        //inside a setTimeout with 0 to prevent the cannot update state from a child component error
        //which happens despite this being called from an onClick handler
        setTimeout(() => {
            // Reset the currently displayed configuration to original
            setCurrentConfiguration(hNodeColumns.map(c => hNodeToEntry(c)));
            // For now, also reset the grid. Though we might want to require an "OK" click for that?
            // if we _don't_ do this, you can cancel the "reset" by clicking outside the popup...
            setStoredColumnHNodes(hNodeColumns.map(c => hNodeToEntry(c)));
        });
    }, [hNodeColumns, setStoredColumnHNodes]);

    const onOkClick = useCallback(() => {
        if (!currentConfiguration.find(c => !c.hidden)) {
            //NO GO, we need at least 1 column!!!!!
            publish(
                { isError: true, message: 'At least 1 column should be visible.' },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
            return;
        }
        //persist the currently displayed configuration to (longer term) memory
        setStoredColumnHNodes(cloneDeep(currentConfiguration));
        //and close the portal
        setPortalOpen(false);
    }, [setStoredColumnHNodes, currentConfiguration, publish]);

    // === Opening and Closing ===
    useEffect(() => {
        if (!namespace || !relation || !allowCustomization) {
            return;
        }
        return subscribe(
            { verb: 'open', namespace: 'application', relation: 'component', type: 'columnCustomization' },
            () => {
                //just in case: reset the displayedConfiguration to whatever is in store
                setCurrentConfiguration(cloneDeep(storedColumnHNodes));
                //and open the portal
                setPortalOpen(true);
            }
        );
    }, [subscribe, namespace, relation, allowCustomization, storedColumnHNodes]);

    // If portal is unmounted by clicking the background, set the PortalOpen property correctly
    const onUnmount = useCallback(() => {
        setPortalOpen(false);
    }, []);

    // prettier-ignore
    const portal = rc(ConfigurationPortal,  { id: `cp-${id}`, onUnmount },
        rc(ConfigurationPortalContainer, {onClick: preventBubbleUp},
            title && rc(Title, null, title),
            rc(DndProvider, { options: HTML5toTouch },
                rc(scrollContainer, null,
                    rc(OrderableList, { list: currentConfiguration, setList: setCurrentConfiguration },
                        currentConfiguration.map((entry, index)=>
                            rc(_p.DraggableRow,{
                                id: `cpc-row${entry.id}-${index}`,
                                key:`cpc-row${entry.id}`,
                                index,
                                entry,
                                onChange: moveEntry,
                                onClick: toggleVisibility
                            })
                        )
                    )
                )
            ),
            rc(ButtonBar, null,
                rc(Button, { value: 'RESET', buttonStyle: 'primary', onClick: onResetClick }),
                rc(Button, { value: 'OK', buttonStyle: 'Positive', onClick: onOkClick })
            )
        )
    );

    if (!portalOpen || !allowCustomization) {
        return [gridColumns, null, gridColumns.length > 0];
    }

    return [gridColumns, portal, gridColumns.length > 0];
}

const hNodeToEntry = hNode => {
    const title = hNode.children?.filter(c => c.hNodeTypeGroup === 'listColumn')?.title || hNode.title || '...';
    return {
        id: hNode.id,
        title: title,
        hidden: hNode.hidden ?? false
    };
};

/**
 * Get the name of the state to use for storing column customizations
 *
 * @param {string} pathname
 * @param {string} namespace
 * @param {string} relation
 * @returns {string} The name of the state to use for storing column customizations
 */
function getCustomizationStateName(pathname, namespace, relation) {
    return `${pathname}.${namespace}.${relation}-columns`;
}

function preventBubbleUp(e) {
    e.preventDefault();
    e.stopPropagation();
}
