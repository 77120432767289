export default Object.freeze({
    /** (Temporary) customized version of AT */
    AMERICAN_WATER_ASSET_TRACKING: '100000000000100000000078',
    /**Also known as RedBeam */
    ASSET_TRACKING: '100000000000100000000077',
    /** Simplified version of AT for use with 3rd Party Flair system */
    FLAIR: '10000000000010464c414952',
    ITEM_TRACKING: '100000000000100000000001',
    META_DATA_CREATOR: '59664ac4ac34d504212c0db8',
    //to be removed after successfully completing creating V3
    META_DATA_CREATOR_WIP: '603ec9c15a49667064a222dc',
    NUCLEUS: '100000000000100000000004',
    ONE_TOUCH: '100000000000100000000008',
    /** Duke specific Add-on for Item Tracking  */
    WISE_ID: '606cc09f23cb94dc2dceb5d7',
    WORK_IN_PROCESS: '64ff73f08a86850bcaa3aba9',
    /** CAPSTONE CUSTOM APP */
    FREIGHT_RUNNER: '65e8ef647334e2d8f4502408'
});
