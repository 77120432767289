import isValidDate from 'date-fns/isValid';
import conversions from '@sstdev/lib_epc-conversions';
import fileImport from '../../../FORMS/importNamespace/importRelation/fileImport';

const _p = { loadFile: fileImport.getData };
export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

const BATCH_SIZE = 100;

export default {
    verb: 'willCreate',
    namespace: 'import',
    relation: 'import',
    priority: 20, // before the default willCreate
    description: 'Load and Validate the data that is to be imported',
    // this is the actual logic:
    logic: willCreate,
    // error handling to avoid the progress staying on the screen:
    onError
};

/**
 * @param {{
 *      error: Error;
 *      data: T;
 *      context: Context;
 *      dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>,
 *      workflowStack: WorkflowStack[]
 * }} parameters
 * */
function onError({ error, dispatch }) {
    dispatch(
        {
            mainTitle: 'Reading File'
        },
        { verb: 'reset', namespace: 'application', relation: 'progress' }
    );
    dispatch(
        {
            mainTitle: 'Importing'
        },
        { verb: 'reset', namespace: 'application', relation: 'progress' }
    );
    throw error;
}

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willCreate({ data, log, dispatch }) {
    const {
        newRecord: {
            _id,
            title,
            firstRowContainsHeader,
            foreignNamespace,
            foreignRelation,
            columns,
            file: [file] = []
        } = {}
    } = data || { newRecord: {} };
    if (!_id) return;
    const startedAt = new Date();
    dispatch(
        {
            mainTitle: 'Reading File',
            title: file.name,
            current: 0,
            total: 1
        },
        { verb: 'update', namespace: 'application', relation: 'progress' }
    );

    const rows = await _p.loadFile(file);
    dispatch(
        {
            mainTitle: 'Reading File'
        },
        { verb: 'reset', namespace: 'application', relation: 'progress' }
    );

    // assume 2sec per request, or 30 batches/minute,
    // a little slower than our max rate of 50/minute
    // with smaller volumes, it will be much closer to that rate,
    // but with larger files, it will go much over it.
    const estimatedMinutes = Math.ceil(rows.length / (30 * BATCH_SIZE));
    let description = 'One moment please while we import your data.';
    if (estimatedMinutes > 1) {
        description = `Estimated total time to completion: about ${estimatedMinutes} minutes.`;
    }
    dispatch(
        {
            mainTitle: 'Importing',
            description,
            title,
            current: 1,
            total: rows.length / BATCH_SIZE + 1
        },
        { verb: 'update', namespace: 'application', relation: 'progress' }
    );

    const parsedData = rows
        .map(transformRowToObject(columns, firstRowContainsHeader, log, foreignNamespace, foreignRelation))
        .filter(x => !!x);
    // if we get this far, close the import dialog
    dispatch({}, { verb: 'close', namespace: foreignNamespace, relation: foreignRelation });

    if (parsedData.slice(0, 100).every(d => d.errors?.length)) {
        log.error(`Import "${title}" failed: No valid data in first 100 rows.`);

        dispatch(
            {
                message: `Import "${title}" failed: No valid data in first 100 rows. Import aborted.`,
                timeout: 5000,
                addToList: true,
                isError: true
            },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
        throw new Error(`Import "${title}" failed: No valid data in first 100 rows. Import aborted.`);
    }
    return {
        ...data,
        newRecord: {
            _id,
            title,
            firstRowContainsHeader,
            foreignNamespace,
            foreignRelation,
            columns,
            fileName: file.name,
            fileSize: file.size,
            startedAt,
            data: parsedData
        }
    };
}

export const transformRowToObject =
    (columns, firstRowContainsHeader, log, foreignNamespace, foreignRelation) => (row, rowNum) => {
        if (firstRowContainsHeader && rowNum === 0) return undefined;
        if (!row.some(x => x?.trim && x.trim())) return undefined;
        const result = columns.reduce((result, column, index) => {
            const def = column._meta;
            // don't do anything for skipped columns, or if there is no data for this column
            if (def.id === '_skip' || row.length <= index) return result;
            let key;
            try {
                if (def.isArrayElement) {
                    result[def.arrayProperty] = result[def.arrayProperty] || [];
                    result[def.arrayProperty][def.arrayIndex] = result[def.arrayProperty][def.arrayIndex] || {};
                    result[def.arrayProperty][def.arrayIndex][def.propertyName] = formatData(row[index], def);
                } else if (def.dataType && def.dataType.type === 'Lookup') {
                    key = `${def.foreignNamespace}:${def.originalRelation || def.foreignRelation}`;
                    result[key] = result[key] || {};
                    result[key][def.propertyName] = formatData(row[index], def);
                } else {
                    // Otherwise just assign the value
                    key = def.propertyName;
                    result[key] = formatData(row[index], def);
                }
            } catch (error) {
                const value = row[index];
                log.error(error);
                result.errors = result.errors || [];
                // if there is an error and the value fails validation, nothing is returned
                // so we need to add the value to the error object so the user can see what failed
                result.errors.push({
                    column: key,
                    message: error.message,
                    value
                });
            }

            return result;
        }, {});

        // Validate organization:person has either lastName or personId
        if (foreignNamespace === 'organization' && foreignRelation === 'person') {
            const { lastName, personId } = result;
            if (!lastName && !personId) {
                result.errors = result.errors || [];
                result.errors.push({
                    column: 'organization:person',
                    message: 'A Person must have either a Last Name or Person ID',
                    value: row
                });
                return result;
            }
        }

        return result;
    };

const formatData = (value, field) => {
    if (field.required && !value && field.dataType?.type !== 'Boolean') {
        throw new Error(`Missing value for required field ${field.title}`);
    }
    value = value && typeof value === 'string' ? value.trim() : value;
    // if it is not required, and nothing is there, don't complain.
    if (!value && (!field.dataType || !['Boolean', 'Integer'].includes(field.dataType.type))) {
        return undefined;
    }

    // validate basic/general limits
    if (field.minLength && value.length < field.minLength) {
        const add = field.minLength < field.maxLength ? 'or more ' : '';
        throw new Error(`${field.title} should have ${field.minLength} ${add}characters`);
    }
    if (field.maxLength && value.length > field.maxLength) {
        const add = field.minLength < field.maxLength ? 'or less ' : '';
        throw new Error(`${field.title} should have ${field.maxLength} ${add}characters`);
    }

    if (!field.dataType || !field.dataType.type) {
        // no datatype => string, therefore, just return the data as string
        return value?.toString();
    }

    // all 'Lookup' fields should be converted to strings
    if (field.dataType.type === 'Lookup') {
        return value?.toString();
    }

    // special field
    // otherwise, do some validating and parsing
    switch (field.dataType.type) {
        case 'Hexadecimal': {
            const isValid = /^[0-9A-Fa-f]*$/g.test(value.trim());
            if (isValid) {
                return value;
            } else {
                throw new Error(`${field.title} does not contain a valid HEX value`);
            }
        }
        case 'ASCII': {
            return conversions.ascii.toHex(value.trim(), true);
        }
        case 'Currency': {
            if (typeof value === 'undefined') return undefined;
            const replaceable = new RegExp(`${getThousandsSeparator(value)}|£|€`, 'g');
            // `$` doesn't play nice in regexp for some reason. just do it separately
            const valueWithoutLocaleFormatting = value.toString().replace(replaceable, '').replaceAll('$', '');
            const parsed = Number(valueWithoutLocaleFormatting);
            if (isNaN(parsed)) {
                throw new Error(`${field.title} does not contain a valid numeric value. `);
            }
            if (typeof field.min !== 'undefined' && parsed < field.min) {
                throw new Error(`${field.title} should be more than or equal to ${field.min}. `);
            }
            if (typeof field.max !== 'undefined' && parsed > field.max) {
                throw new Error(`${field.title} should be less than or equal to ${field.max}. `);
            }
            return parsed;
        }
        case 'Integer': {
            if (typeof value === 'undefined') return 0;
            const parsed = Number(value);
            if (isNaN(parsed)) {
                throw new Error(`${field.title} does not contain a valid Integer value. `);
            }
            if (typeof field.min !== 'undefined' && parsed < field.min) {
                throw new Error(`${field.title} should be more than or equal to ${field.min}. `);
            }
            if (typeof field.max !== 'undefined' && parsed > field.max) {
                throw new Error(`${field.title} should be less than or equal to ${field.max}. `);
            }
            return parsed;
        }
        case 'Date/Time': {
            try {
                if (isValidDate(new Date(value))) {
                    return new Date(value);
                } else {
                    throw new Error(`${field.title} does not contain a valid Date/Time value`);
                }
            } catch (error) {
                throw new Error(`${field.title} does not contain a valid Date/Time value`);
            }
        }
        case 'Email': {
            const isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value);
            if (isValid) {
                return value;
            } else {
                throw new Error(`${field.title} does not contain a valid Email value`);
            }
        }
        case 'Boolean': {
            const stringValue = value?.toString()?.trim();
            // if the value is undefined, null, or an empty string, return the default value
            // for example, if a user is importing an asset that does not have the 'Active' column and value in the import file,
            // but the 'Active' toggle has a default value of true, then the asset will be created as active.
            if ([undefined, null, ''].includes(stringValue)) {
                return field.defaultValue !== undefined ? field.defaultValue : undefined;
            }

            const parsed = !/^(false|no|0)$/i.test(stringValue);
            return parsed;
        }
    }

    return value;
};

const getThousandsSeparator = value => {
    // ideally, look at the string, see if it is #,###.## format
    // or the non-us #.###,## format
    const stringValue = value.toString();
    const nonDigits = stringValue.replace(/\d/g, '');
    if (nonDigits.includes(',.')) {
        return ',';
    } else if (nonDigits.includes('.,')) {
        return '.';
    }
    // otherwise assume US
    return ',';
};
