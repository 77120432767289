import { useTheme } from 'styled-components';
import getColumnWidth from './getColumnWidth';
import useUserContext from '../../../hooks/useUserContext';
import useCalculatedColumn from './useCalculatedColumn';
import useActiveRecord from '../../../hooks/useActiveRecord';
import useSplashRecord from '../../../hooks/useSplashRecord';
import useNavigationSelection from '../../../hooks/useNavigationSelection';
import getColumnCell from './getColumnCell';

export const _private = {
    useActiveRecord,
    useCalculatedColumn,
    useNavigationSelection,
    useSplashRecord,
    getColumnCell,
    getColumnWidth
};

export default function useColumnCell(
    record,
    columnHNode,
    style,
    skipUndefined = false,
    platformSpecificValueLookup,
    setWidth = true
) {
    const theme = useTheme();
    const user = useUserContext();

    const activeRecord = _private.useActiveRecord();
    const splashRecord = _private.useSplashRecord();
    const navigationSelection = _private.useNavigationSelection();
    const featureFlags = user.allFeatureFlags;
    const width = setWidth ? _private.getColumnWidth(columnHNode, theme) : undefined;

    const calculatedColumn = _private.useCalculatedColumn({
        record,
        columnHNode,
        style,
        skipUndefined,
        platformSpecificValueLookup,
        theme,
        width,
        setWidth,
        featureFlags,
        activeRecord,
        splashRecord,
        navigationSelection
    });
    if (calculatedColumn != null) return calculatedColumn;

    return _private.getColumnCell({
        record,
        columnHNode,
        style,
        skipUndefined,
        platformSpecificValueLookup,
        theme,
        width,
        setWidth,
        featureFlags,
        activeRecord,
        splashRecord,
        navigationSelection
    });
}
