import logging from '@sstdev/lib_logging';
import server from './getFromServer';
import pThrottle from 'p-throttle';
import { dateMax, dateMin } from '../../valueUtilities';
import SYNC_TYPES from '../../constants/SYNC_TYPES';

//We are rate limited to
//50 requests per path per minute
//300 requests across all paths per minute
//6 requests (paths) max at a single time
//so, we can sync 6 relations in parallel, with every request having to take at least 1.2 seconds
const MAX_REQUEST_PER_TIME_FRAME = 600; //corresponds to USER_RATE_LIMIT
const MAX_PATH_REQUEST_PER_TIME_FRAME = 100; //Corresponds to USER_PATH_RATE_LIMIT
const REQUEST_RATE_TIME_FRAME = 60000; //1 minute in ms
const crossRelationThrottle = pThrottle({
    //throttle at 1 per part of interval, rather than MAX_REQUEST_PER_TIME_FRAME as limit and REQUEST_RATE_TIME_FRAME as interval
    // to avoid an initial surge
    limit: 1,
    interval: REQUEST_RATE_TIME_FRAME / MAX_REQUEST_PER_TIME_FRAME
});

const _p = {
    getThrottledBatch: crossRelationThrottle(server.getBatchWithRetry),
    getThrottledCount: crossRelationThrottle(server.geCountWithRetry),
    getPerRelationThrottle: () =>
        pThrottle({
            //throttle at 1 per part of interval, rather than MAX_REQUEST_PER_TIME_FRAME as limit and REQUEST_RATE_TIME_FRAME as interval
            // to avoid an initial surge
            limit: 1,
            interval: MAX_PATH_REQUEST_PER_TIME_FRAME / MAX_REQUEST_PER_TIME_FRAME
        }),
    removeLocalRecordsOlderThanMinModifiedTime
};

export const _private = _p;
/**
 * @typedef {{
 *   initialSyncFinished: boolean,
 *   lastSyncRecordCompleted: string,
 *   nextBatchdQueryIndexKey: string,
 *   lastSyncTime: string,
 *   syncStartTime: string,
 *   lastPurgeTime: sting,
 *   lastBatch: boolean
 * }} StorageState
 *
 * @typedef {Array<{namespace:string, relation:string, getStorageState:function():StorageState}>} RelationToSync
 */

/**
 *
 * @param {Object} database
 * @param {function} database.bulkUpsert
 * @param {function} database.relationDb
 * @param {function} database.isNewDatabase
 * @param {function} database.setStorageState
 * @param {function} database.resetStorageState
 * @param {function} database.publish
 * @param {number} maxBatchSize
 */
export default function syncSingleRelation(database, maxBatchSize) {
    /**
     *
     * @param {RelationToSync} relationToSync
     * @param {boolean} obtrusive Whether to keep a progress dialog updated or not (it prevents the user from interacting with the app)
     */
    return async function syncRelation(relationToSync, obtrusive, syncType) {
        //Reject if in progress

        const { namespace, relation } = relationToSync;
        const getStorageState = propertyName => database.getStorageState(namespace.title, relation.title, propertyName);
        if (getStorageState().syncStartTime) return { postPersistence: () => {} };
        const { limitSyncSize = false } = relation;

        logging.debug(`[SYNCHRONIZATION] Syncing ${relation.prettyName || relation.title}`);

        // set defaults value for syncType if not provided.
        if (syncType == null) {
            syncType = limitSyncSize ? SYNC_TYPES.sizeLimitedLatestOnly : SYNC_TYPES.default;
        } else {
            // only allow limitSyncSize relations to perform size limited syncs.
            if (
                !limitSyncSize &&
                [SYNC_TYPES.sizeLimitedFullBatch, SYNC_TYPES.sizeLimitedLatestOnly].includes(syncType)
            ) {
                throw new Error(
                    `${syncType} can only be used with relations having limitSyncSize set to true in Blockly.`
                );
            }
        }

        //Prepare storage
        await prepareStorageState(database, relationToSync);
        //Set in progress
        const syncStartTimeForDuration = new Date();
        const syncStartTime = new Date().toISOString();
        database.setStorageState(namespace.title, relation.title, 'syncStartTime', syncStartTime);

        //get all removed records
        if (getStorageState().lastSyncTime) {
            await processAllBatches(relationToSync, removeFrom(database), {
                header: { ifDeletedSince: getStorageState().lastSyncTime },
                maxBatchSize,
                obtrusive,
                publish: database.publish,
                prefix: 'Cleaning Up '
            });
        }

        //get all new and updated records
        const result = await processAllBatches(relationToSync, insertInto(database), {
            header: { ifModifiedSince: getStorageState().lastSyncTime },
            earliestModifiedTime: getStorageState().earliestModifiedTime,
            latestModifiedTime: getStorageState().latestModifiedTime,
            maxBatchSize,
            obtrusive,
            publish: database.publish,
            syncType
        });

        // if there are results and this is a size limited sync
        if (
            result.length > 0 &&
            [SYNC_TYPES.sizeLimitedFullBatch, SYNC_TYPES.sizeLimitedLatestOnly].includes(syncType)
        ) {
            // Use results to calculate new latestModifiedTime and earliestModifiedTime
            const latestModifiedTime = dateMax(
                result[0]?.meta?.serverModifiedTime,
                getStorageState().latestModifiedTime
            );
            database.setStorageState(
                namespace.title,
                relation.title,
                'latestModifiedTime',
                latestModifiedTime.toISOString()
            );
            const earliestModifiedTime = dateMin(
                result[result.length - 1]?.meta?.serverModifiedTime,
                getStorageState().earliestModifiedTime
            );
            database.setStorageState(
                namespace.title,
                relation.title,
                'earliestModifiedTime',
                earliestModifiedTime.toISOString()
            );

            // If we get a max sized batch of these, it means that it was almost certainly limited
            // at the server and there are more.  For this reason, it is possible that
            // previously synched records have changed and were not included, so remove local
            // records older than the the oldest synched record to make sure there is no stale
            // data. Incidentally, this will help limit the amount of local data for really
            // high volume tenants. It will not completely cure the problem though, so the
            // truncateCollectionToMaxSize call below is necessary.
            if (syncType === SYNC_TYPES.sizeLimitedLatestOnly && result.length === maxBatchSize) {
                await _p.removeLocalRecordsOlderThanMinModifiedTime(
                    relationToSync,
                    result,
                    removeOlderRecords(database)
                );
            }

            // If the local collection has grown beyond the allowed boundary, remove the oldest
            // records until it is the max size
            const maxSize = database.settings.maxRecordsInLimitedSizeCollection;
            const relationTotalSize = database.relationTotalSize({
                namespace: namespace.title,
                relation: relation.title
            });
            if (relationTotalSize > maxSize) {
                const collection = database.relationDb(namespace.title, relation.title);
                await collection.truncateCollectionToMaxSize(maxSize);
            }
        }

        const postPersistence = () => {
            if (result.length > 0) {
                //Update last synced dateTime
                database.setStorageState(namespace.title, relation.title, 'lastSyncTime', syncStartTime);
            }
        };
        //Release from in progress
        database.setStorageState(namespace.title, relation.title, 'syncStartTime', undefined);
        database.setStorageState(namespace.title, relation.title, 'initialSyncFinished', true);
        const durationSeconds = (new Date() - syncStartTimeForDuration) / 1000;
        logging.debug(
            `[SYNCHRONIZATION] Syncing ${relation.prettyName || relation.title} Complete. ${durationSeconds} seconds.`
        );

        return { result, postPersistence };
    };
}

async function prepareStorageState({ isNewDatabase, resetStorageState }, { namespace, relation }) {
    return isNewDatabase(namespace.title, relation.title).then(isNew => {
        // If the database has been deleted but the storage state
        // was not updated (seems to happen when reinstalling app
        // on android), then reset storage state.
        // Include update sequence in new storage state so this doesn't end up
        // in a loop (see database.isNewDatabase()).
        if (isNew) {
            resetStorageState(namespace.title, relation.title);
        }
    });
}

/**
 * Get (and process) the first batch
 * If the batch is full
 *    if we are providing feedback to the UI:
 *        Do a count for the total number of results
 *    Sync all remaining batches
 * @param {RelationToSync} relationToSync
 * @param {function(RelationToSync, Array<Object>):Promise<void>} operationOnDatabase
 * @param {Object} options
 * @param {Object} options.header
 * @param {string} [options.header.ifDeletedSince]
 * @param {string} [options.header.ifModifiedSince]
 * @param {function} options.publish
 * @param {boolean} options.obtrusive
 * @param {number} options.maxBatchSize
 * @param {string} options.prefix
 */
async function processAllBatches(
    { namespace, relation },
    operationOnDatabase,
    { maxBatchSize, obtrusive, publish, header, prefix = '', earliestModifiedTime, latestModifiedTime, syncType }
) {
    const throttle = _p.getPerRelationThrottle();
    const getThrottledBatch = throttle(_p.getThrottledBatch);
    const getThrottledCount = throttle(_p.getThrottledCount);

    //Get first batch of data (since last synced date)
    let result = await getThrottledBatch(namespace.title, relation.title, {
        ...header,
        lastRecordId: undefined,
        limit: maxBatchSize,
        earliestModifiedTime,
        latestModifiedTime,
        syncType
    });
    await operationOnDatabase({ namespace, relation }, result);

    let total = 1;
    let relationSpecificFeedback = false;
    let syncedSoFar = result.length;

    // If showing feedback and last result filled the maximum batch size (so assuming more records are needed
    // (unless limitSyncSize is true, in which case only one batch is ever needed.)),
    // get statistics for feedback and provide feedback for first batch.
    if (
        syncType === SYNC_TYPES.default &&
        obtrusive &&
        syncedSoFar >= maxBatchSize &&
        supportsAggregate(namespace, relation)
    ) {
        // show relation progress bar to 0 of 1 to get something going
        relationSpecificFeedback = true;
        publish(
            {
                mainTitle: 'Syncing In Progress...',
                title: `${prefix}${relation.prettyName || relation.title}`,
                current: 0,
                total: 1
            },
            { verb: 'update', namespace: 'application', relation: 'progress' }
        );

        //  Get count of all records for relation from server
        total = await getThrottledCount(namespace.title, relation.title, header);
        // update relation progress bar to maxBatchSize of count
        // as we already synced the first batch
        publish(
            {
                mainTitle: 'Syncing In Progress...',
                title: `${prefix}${relation.prettyName || relation.title}`,
                current: syncedSoFar,
                total: total
            },
            { verb: 'update', namespace: 'application', relation: 'progress' }
        );
    }

    // If last result filled the maximum batch size (so assuming more records are needed
    // (unless limitSyncSize is true, in which case only one batch is ever needed.)),
    // get the rest of the batches of records for the relation.
    while (syncType === SYNC_TYPES.default && result.length === maxBatchSize) {
        // Get batch 1 of data (since last synced date & > last result's _id)
        result = await getThrottledBatch(namespace.title, relation.title, {
            ...header,
            lastRecordId: result[result.length - 1]._id,
            limit: maxBatchSize
        });
        await operationOnDatabase({ namespace, relation }, result);
        // update relation progress bar to (prev value + result.length) of count
        syncedSoFar += result.length;
        if (obtrusive) {
            // update relation progress bar to maxBatchSize of count
            publish(
                {
                    mainTitle: 'Syncing In Progress...',
                    title: `${prefix}${relation.prettyName || relation.title}`,
                    current: syncedSoFar,
                    total: total
                },
                { verb: 'update', namespace: 'application', relation: 'progress' }
            );
        }
    }

    // Should be finished with all batches for the relation, so provide feedback if necessary.
    if (obtrusive && relationSpecificFeedback) {
        // Remove this relation's progress bar, if necessary.
        // If we never displayed it in the first place, this is a noop.
        publish(
            {
                mainTitle: 'Syncing In Progress...',
                title: `${prefix}${relation.prettyName || relation.title}`
            },
            { verb: 'reset', namespace: 'application', relation: 'progress' }
        );
    }

    return result;
}

async function removeLocalRecordsOlderThanMinModifiedTime(relationToSync, result, removeOlderRecords) {
    // Get minimum server modified time for result batch
    // (Batch should be sorted by serverModifiedTime descending,
    // so find first one existing, starting from the bottom and
    // stepping upwards.)
    let minServerModifiedTime;
    for (let i = result.length + 1; i >= 0; i--) {
        minServerModifiedTime = result?.[i]?.meta?.serverModifiedTime;
        if (minServerModifiedTime) break;
    }
    // Ideally, this should be more granular because it is possible
    // that multiple records have the same serverModifiedTime.  However, the overlap
    // is not likely to be significant, so this might be good enough.  If this proves to be
    // a problem somehow, the alternative might involve ordering _ids within serverModifiedTime
    // so the records outside the sync can be emperically identified.
    await removeOlderRecords(relationToSync, minServerModifiedTime);
}

// identity silo does not support aggregate (e.g. count operation) yet
const supportsAggregate = ns => !['identity'].includes(ns.title);

const insertInto =
    database =>
    async ({ namespace, relation }, items) => {
        if (!items || items.length === 0) return;
        return database.bulkUpsert(namespace.title, relation.title, items);
    };

const removeFrom =
    database =>
    async ({ namespace, relation }, items) => {
        if (!items || items.length === 0) return Promise.resolve();
        const db = database.relationDb(namespace.title, relation.title);
        // (really) remove them all from the database.
        return Promise.allSettled(items.map(i => db.remove(i)));
    };

const removeOlderRecords =
    database =>
    async ({ namespace, relation }, oldestAllowedTime) => {
        const db = database.relationDb(namespace.title, relation.title);
        return db.removeMany({ trueDelete: true, criteria: { 'meta.serverModifiedTime': { $lt: oldestAllowedTime } } });
    };
