const notHandledHere = [
    { namespace: 'security', relation: 'profile' },
    { namespace: 'import', relation: 'import' },
    { namespace: 'application' },
    { namespace: 'sensor' },
    { namespace: 'file' },
    { namespace: 'metaui', relation: 'validations' },
    { namespace: 'metaui', relation: 'dataDictionary' }
];
export default notHandledHere;
