import { COMMON_COLOR_SCHEME as scheme, COLORS, CHART_COLORS } from 'lib_ui-primitives';
import zindex from './zindexes';
export default function themeValues(otherThemeValues = { mobile: false }) {
    const { darkMode = false, mobile = false, tablet = false, width, height, smallMobile } = otherThemeValues;
    let theme = {
        zindex,
        // eslint-disable-next-line no-undef
        native: __SST_REACT_NATIVE__ || __TEST_RFID__,
        viewPort: mobile ? 'MOBILE' : tablet ? 'TABLET' : 'DESKTOP',
        smallMobile,
        colorScheme: scheme,
        baseBackgroundColor: darkMode ? '#000' : scheme.background,
        baseBackgroundColorDarker: darkMode ? '#333' : '#ccc',
        // with each layer, progressively lightens/darkens the parent or baseBackgroundColor
        backgroundColorLighter: darkMode ? 'rgba(255, 255, 255, 0.02)' : 'rgba(0, 0, 0, 0.02)',
        backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0, 0, 0, 0.06)',
        backgroundColorDarker: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        backgroundModal: darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
        backgroundHint: darkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
        boxShadowColor: darkMode ? '#ffffff50' : '#00000050',
        defaultFontColor: darkMode ? scheme['white-text'] : COLORS.trueBlack,
        invertedFontColor: darkMode ? COLORS.trueBlack : scheme['white-text'],
        errorFontColor: scheme.error,
        unobtrusiveFontColor: darkMode ? '#bbbbbb' : '#444444',
        subheadingFontColor: darkMode ? scheme['light-accent-text'] : scheme['dark-accent-text'],
        font: 'Roboto',
        fontAlternate: 'Nunito',
        fontSizeSmall: 12,
        fontSize: 16,
        fontSizeLarge: 24,
        fontSizeLabel: 12,
        disabledFontColor: darkMode ? 'rgba(255, 255, 255, 0.55)' : 'rgba(0, 0, 0, 0.55)',
        disabledBackgroundColor: scheme['disabled-background'],
        disabledColor: scheme['disabled-color'],
        drawerWidth: mobile ? width : 240,
        gridLineColor: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        headerHeight: 60,
        headerBottomBorderWidth: 3,
        listLineHeight: 35,
        listActiveBackgroundColor: darkMode ? '#D0EDF866' : scheme['accent-1-highlight'],
        listSelectedBackgroundColor: darkMode ? '#D0EDF866' : scheme['accent-1-highlight'],
        progressBarColor: scheme['primary'],
        borderRadius: 3,
        viewPadding: 6,
        viewPaddingMore: 12,
        viewMargin: 6,
        viewMarginMore: 12,
        textMargin: mobile ? 3 : 6,
        textPadding: 6,
        textPaddingMore: 12,
        minModalWidth: mobile ? width : 300,
        /* For whatever reason, 90% does not yield the same value as width * 0.9 */
        maxModalWidth: mobile ? width : width * 0.9,
        minModalHeight: mobile ? height : 0,
        maxTableColumnWidth: 200,
        spinnerColor: darkMode ? scheme['white-text'] : scheme['primary'],
        outerMenus: {
            backgroundColor: '#5C5C60',
            fontColor: scheme['white-text'],
            disabledFontColor: 'rgba(255, 255, 255, 0.45)',
            margin: 12,
            menuItemPadding: 12,
            disabledBackgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        iconFont: 'material-icons',
        iconSize: 24,
        labelColor: darkMode ? scheme['white-text'] : scheme['black-text'],
        labelForEmptyField: darkMode ? scheme['light-action-available'] : scheme['dark-action-available'],
        linkColor: darkMode ? COLORS.lightBlue : COLORS.blue,
        linkFontSize: 12,
        disabledLabelColor: darkMode ? 'rgba(255, 255, 255, 0.55)' : 'rgba(0, 0, 0, 0.55)',
        borderColor: darkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
        form: {
            // These pseudo-static sizes are used for FormField components (which encapsulate
            // most form data entry controls).
            // This seems to work well with tested useCases, but it is possible that
            // additional nesting of fieldsets within fieldsets might create less
            // aesthetically pleasing results and a more dynamic approach might be needed.
            entryMinWidth: Math.min(280, width - 30),
            entryMaxWidth: Math.min(350, width - 30),
            inputBorderRadius: 3,
            dropdownMinHeight: 200
        },
        menu: {
            borderColor: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            backgroundColor: darkMode ? 'rgb(51, 51, 51)' : 'rgb(204, 204, 204)',
            menuItemPadding: 12
        },
        button: {
            fontSize: 14,
            borderRadius: 2,
            roundDiameter: 32,
            roundDiameterLarge: 48,
            // If darkMode uses lighter colors, then invert the text for contrast
            //fontColor: darkMode ? scheme['black-text'] : scheme['white-text'],
            fontColor: scheme['white-text'],
            height: 32,
            maxHeight: 40,
            maxWidth: 200,
            minWidth: 32,
            padding: 8,
            primary: scheme['primary'],
            primaryHover: scheme['primary-hover'],
            primaryHighlight: scheme['primary-highlight'],
            success: scheme['success'],
            successHover: scheme['success-hover'],
            successHighlight: scheme['success-highlight'],
            warn: scheme.warn,
            warnHover: scheme['warn-hover'],
            warnHighlight: scheme['warn-highlight'],
            error: scheme.error,
            errorHover: scheme['error-hover'],
            errorHighlight: scheme['error-highlight'],
            negative: scheme.error,
            negativeHover: scheme['error-hover'],
            negativeHighlight: scheme['error-highlight'],
            transparent: '#00000000',
            transparentHover: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            transparentHighlight: darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
            base: darkMode ? '#000' : scheme.background,
            baseHover: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            baseHighlight: darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
            accentOne: scheme['accent-1'],
            accentOneHover: scheme['accent-1-hover'],
            accentOneHighlight: scheme['accent-1-highlight'],
            accentTwo: scheme['accent-2'],
            accentTwoHover: scheme['accent-2-hover'],
            accentTwoHighlight: scheme['accent-2-highlight'],
            // For pseudo-disabled components that still require interaction (like notifications button)
            gray: COLORS.darkGray,
            grayHover: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            grayHighlight: darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'
        },
        card: {
            padding: 16,
            // Header matches selected navHeadings color scheme and header colors from UI v1
            // Don't worry, looks fine on darkmode
            header: {
                textColor: scheme['white-text'],
                backgroundColor: scheme['background-dark']
            },
            body: {},
            footer: {
                backgroundColor: 'transparent',
                paddingY: 8
            }
        },
        dataAggregation: {
            fontColor: darkMode ? scheme['primary-hover'] : scheme.primary
        },
        switch: {
            checked: scheme['primary'],
            unchecked: darkMode ? scheme['primary-hover'] : scheme['dark-action-available'],
            disabled: scheme['disabled-color'],
            intermediate: scheme['accent-2'],
            backgroundColorChecked: COLORS.paleGreen, // darkMode ? scheme['disabled-background'] : scheme['primary-highlight'],
            backgroundColorUnchecked: COLORS.paleRed,
            disabledBackgroundColor: scheme['disabled-background'],
            backgroundColorIntermediate: darkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)'
        },
        slider: {
            selected: darkMode ? scheme['primary'] : scheme['primary-hover'],
            hover: darkMode ? scheme['primary-hover'] : scheme['primary'],
            backgroundColor: darkMode ? scheme['disabled-background'] : scheme['background-light'],
            disabled: darkMode ? 'rgba(255, 255, 255, 0.55)' : 'rgba(0, 0, 0, 0.55)'
        },
        activeBorderColor: scheme.primary,
        chartColors: darkMode ? CHART_COLORS.dark : CHART_COLORS.light,
        ...otherThemeValues
    };
    if (mobile) {
        theme = {
            ...theme,
            listLineHeight: 50,
            borderRadius: 4,
            iconSize: 32,
            form: {
                ...theme.form,
                entryMinWidth: Math.min(smallMobile ? 175 : 280, width - 48),
                dropdownMinHeight: '100%'
            },
            button: {
                ...theme.button,
                roundDiameter: 48,
                roundDiameterLarge: 56,
                roundBorderRadius: 28, // % doesn't work on react native
                height: 64,
                minWidth: 64,
                // Remove hover states for mobile.
                successHover: undefined,
                primaryHover: undefined,
                errorHover: undefined,
                warnHover: undefined
            },
            card: {
                ...theme.card,
                padding: 6
            },
            outerMenus: {
                ...theme.outerMenus,
                margin: 0,
                menuItemPadding: 24
            }
        };
    }

    return theme;
}
