import lokijs from 'lokijs';
import logging from '@sstdev/lib_logging';
import adapter, { env, idbAdapter } from './adapter';
import Insert from './insert';
import Update from './update';
import Get from './get';
import Find from './find';
import Clear from './clear';
import Upsert from './upsert';
import Remove from './remove';
import FindPaged from './findPaged';
import BulkUpsert from './bulkUpsert';
import UpdateMany from './updateMany';
import RemoveMany from './removeMany';
import TruncateCollectionToMaxSize from './truncateCollectionToMaxSize';
import Count from './count';
import IndexTools from './indexTools';
import DeleteDatabase from './deleteDatabase';
import { getTitleAlternative } from '../../metadata';
import globalConfig from '../../globalConfig';
import './lokiJsPrototypeOverrides';
import './overrideLokiQueueRebuildEvent';

const MAX_LEXICAL_VALUE = '\uffff';
const MIN_LEXICAL_VALUE = '\u0000';

const _p = {
    adapter,
    idbAdapter,
    env,
    globalConfig
};

export const _private = _p;

/**
 * @class
 */
export default function firepants(mainFileName = 'data/sstlokijs', settings) {
    let loki, close;
    let autosaveInterval = _p.globalConfig().dbAutoSaveInterval ?? 1000;
    if (settings && settings.autosaveInterval) autosaveInterval = settings.autosaveInterval;
    const _mainFileName = mainFileName;

    const startPromise = new Promise((resolve, reject) => {
        function callback(err) {
            if (err != null) {
                logging.error(err);
                reject(err);
                return;
            }
            // If a partition (i.e. persistent record for a collection) was lost, clear
            // anything in the the collection (including indexes, etc).  It will be resynced.
            // (see Database.js getDatabaseRoot())
            if (loki.persistenceAdapter?.failedPartitions?.length > 0) {
                loki.persistenceAdapter.failedPartitions.forEach(f => {
                    const collection = loki.getCollection(f.collectionName);
                    collection.clear({ removeIndices: true });
                });
            }
            logging.debug('[FIREPANTS] Loki DB ready.');

            globalThis.bb = globalThis.bb || {};
            const bb = globalThis.bb;
            bb.loki = loki;
            resolve(loki);
        }

        // Encapsulate this so we can retry if we have to blow away the indexedDB files due to corruption.
        function startLoki() {
            try {
                loki = new lokijs(_mainFileName, {
                    env: _p.env,
                    autosave: false, // This will make tests hang if it is turned on.
                    autosaveInterval,
                    adapter: _p.adapter,
                    autoload: true,
                    autoloadCallback: callback
                });
                close = loki.close;
            } catch (err) {
                reject(err);
            }
        }

        startLoki();
    });

    const root = {
        startPromise,
        loki,
        MIN_LEXICAL_VALUE,
        MAX_LEXICAL_VALUE,
        mainFileName,
        idbAdapter: _p.idbAdapter,
        adapter: _p.adapter
    };
    // Curry this stuff to clean up the code a little;
    // Everything in here is specific to a namespace/relation
    function relationCollection(namespaceTitle, relationTitle) {
        const collectionName = `${namespaceTitle}_${relationTitle}`;
        let collection = loki.getCollection(collectionName);
        if (!collection) {
            const titleProperty = getTitleAlternative(namespaceTitle, relationTitle);
            collection = loki.addCollection(collectionName, {
                unique: ['_id'],
                indices: [titleProperty, 'meta.serverModifiedTime']
            });
        } else {
            // Remove any pre-existing dynamic views.  This is to avoid problems where a filter
            // was applied to the Dynamic view in a previous incarnation and the filter is not
            // valid during startup.  A more efficient method might be to compare default filters
            // as given by metadata the first time the view is requested, but this can be tricky
            // if there are multiple consumers of the view (e.g. grid and search bar).
            for (let i = collection.DynamicViews.length - 1; i >= 0; i--) {
                const name = collection.DynamicViews[i].name;
                collection.removeDynamicView(name);
            }
        }
        const _private = {
            ...root,
            omitFromComparisons: ['meta', '_id', '$loki', '_idx'],
            collection,
            namespaceTitle,
            relationTitle
        };

        // construct collection/relation specific versions of each method
        const insert = Insert(_private);
        const update = Update(_private);
        const get = Get(_private);
        const clear = Clear(_private);
        const find = Find(_private);
        const upsert = Upsert(_private);
        const remove = Remove(_private);
        const findPaged = FindPaged(_private);
        const bulkUpsert = BulkUpsert(_private);
        const updateMany = UpdateMany(_private);
        const removeMany = RemoveMany(_private);
        const count = Count(_private);
        const truncateCollectionToMaxSize = TruncateCollectionToMaxSize(_private);
        const indexTools = IndexTools(_private);
        indexTools.ensureIndexesAreUpdated();

        return {
            _private,
            addLexicalIndex: indexTools.addLexicalIndex,
            bulkUpsert,
            clear,
            count,
            find,
            findPaged,
            get,
            insert,
            remove,
            update,
            updateMany,
            removeMany,
            upsert,
            truncateCollectionToMaxSize
        };
    }
    relationCollection.close = close;
    relationCollection.waitTillDbReady = startPromise;
    relationCollection.deleteDatabase = DeleteDatabase(root);
    relationCollection.dropCollection = dropCollection(startPromise);
    relationCollection.resumePhysicalWrites = resumePhysicalWrites(startPromise, autosaveInterval);
    relationCollection.pausePhysicalWrites = pausePhysicalWrites(startPromise, autosaveInterval);
    return relationCollection;
}

function resumePhysicalWrites(startPromise, autosaveInterval) {
    return async () => {
        logging.debug('[PERSISTENCE] resuming physical writes');
        const loki = await startPromise;
        // Check loki.options, not loki.autosave.
        // Any configuration changes made after loki has already been initialized will be reflected in loki.options.
        if (loki?.options?.autosave === false) {
            loki.configureOptions({
                env: _p.env,
                autosave: true,
                adapter: _p.adapter,
                autosaveInterval
            });
            return new Promise((resolve, reject) => {
                const isDirty = loki.collections.some(col => col.dirty);
                if (isDirty) {
                    loki.saveDatabase(err => {
                        if (err) reject(err);
                        logging.debug('[PERSISTENCE] finished first post-sync write to disk.');
                        resolve();
                    });
                } else resolve();
            });
        }
    };
}

function pausePhysicalWrites(startPromise, autosaveInterval) {
    return async () => {
        logging.debug('[PERSISTENCE] resuming physical writes');
        const loki = await startPromise;
        // Check loki.options, not loki.autosave.
        // Any configuration changes made after loki has already been initialized will be reflected in loki.options.
        if (loki?.options?.autosave === true) {
            loki.configureOptions({
                env: _p.env,
                autosave: false,
                adapter: _p.adapter,
                autosaveInterval
            });
        }
    };
}

function dropCollection(startPromise) {
    // This is a noop if the collection does not exist.
    return async (namespaceTitle, relationTitle) => {
        const collectionName = `${namespaceTitle}_${relationTitle}`;
        const loki = await startPromise;
        if (loki.listCollections().find(c => c.name === collectionName && c.count > 0)) {
            loki.removeCollection(collectionName);
            return true;
        } else return false;
    };
}
