import getHNodeValueWidth from './getHNodeValueWidth';
// This is (hopefully) a conservative estimate meant to  give a little extra space.
// If greater accuracy is needed, there are other options
// https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
// https://github.com/aMarCruz/react-native-text-size
const CHAR_WIDTH_TO_HEIGHT_RATIO = 0.66;
const _p = { getHNodeValueWidth };
export const _private = _p;
export default function getColumnWidth(columnHNode, theme) {
    if (columnHNode == null) return 0;
    const { maxTableColumnWidth, fontSize } = theme;
    const labelWidth = columnHNode.label.length * fontSize * CHAR_WIDTH_TO_HEIGHT_RATIO + 2 * theme.textPadding;
    let contentWidth = _p.getHNodeValueWidth(columnHNode, theme);
    contentWidth += 2 * theme.textPadding;
    // Don't let the width be greater than the max column width
    let result = Math.min(contentWidth, maxTableColumnWidth);
    // Make it at least as long as the label though.
    result = Math.max(labelWidth, result);
    return result;
}
