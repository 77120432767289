import { useEffect, useState, createElement as ce, useCallback, useMemo } from 'react';
import { setupRulesEngine } from 'lib_ui-core';

import { Card, h1, View, Button, styled, webOnlyStyles, ScrollView } from 'lib_ui-primitives';

import useDoubleClick from '../../hooks/useDoubleClick';
import useEventSink from '../../hooks/useEventSink';
import useUserContext from '../../hooks/useUserContext';
import TenantItem from './TenantItem';

let Centered = styled(View).attrs({ name: 'centered' })`
    width: 100%;
    height: 100%;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
`;

Centered = webOnlyStyles(Centered)`
    box-sizing: border-box;
`;

const Main = styled(ScrollView).attrs({
    name: 'main',
    contentContainerStyle: { justifyContent: 'center', flexGrow: 1 }
})``;

let FullScreenBody = styled(Card.Body)`
    ${({ theme }) =>
        !theme.mobile &&
        !theme.tablet &&
        `
    margin-left: auto;
    margin-right: auto;
    min-width: 800px;
    `};
`;

FullScreenBody = webOnlyStyles(FullScreenBody)`
    overflow-y: auto;
    ${({ theme }) => theme.mobile && 'padding-inline-start: 0;'};
`;

const Header = styled(h1)`
    font-size: 24px;
`;

const EMPTY_ARRAY = [];
const EMPTY_USER = {};

const TenantSelection = () => {
    /** @type {import('../../../types').StateHook<import('../../../types').UseCase>} */
    const [selectedUseCase, setSelectedUseCase] = useState();
    const eventSink = useEventSink();
    const [, publish] = eventSink;
    const profile = useUserContext() || EMPTY_USER;
    const { user, userName, password } = profile;

    useEffect(() => {
        setupRulesEngine({}, eventSink);
    }, [eventSink]);

    // if the user only has one tenant and useCase go ahead and select that useCase.
    useEffect(() => {
        if (user?.tenant.length === 1 && user.tenant[0].useCase.length === 1) {
            const tenantId = user.tenant[0]['identity:tenant']._id;
            const useCaseId = user.tenant[0].useCase[0]['metaui:useCase']._id;
            publish(
                { userName, password, tenantId, useCaseId },
                { verb: 'update', namespace: 'security', relation: 'profile', type: 'selectTenant' }
            );
        }
    }, [password, profile, publish, user?.tenant, userName]);

    /**
     * @param {React.MouseEvent<HTMLButtonElement>} event
     */
    // eslint-disable-next-line no-unused-vars
    const onCancel = useCallback(() => {
        publish({ profile }, { verb: 'remove', namespace: 'security', relation: 'profile' });
    }, [profile, publish]);

    /**
     * @params {React.MouseEvent} event
     * @param {import('../../../types').UseCase} [useCaseFromDoubleClick]
     */
    const onSubmit = useCallback(
        (event, useCaseFromDoubleClick) => {
            const useCase = useCaseFromDoubleClick ?? selectedUseCase;
            if (!useCase) return;

            const { user } = profile;
            const tenant = user.tenant.find(
                t =>
                    t['identity:tenant']._id === useCase?.['identity:tenant']._id &&
                    t.useCase.find(uc => uc['metaui:useCase']._id === useCase?.['metaui:useCase']._id)
            );

            publish(
                {
                    ...profile,
                    tenantId: tenant['identity:tenant']._id,
                    useCaseId: useCase['metaui:useCase']._id,
                    type: 'selectTenant'
                },
                { verb: 'update', namespace: 'security', relation: 'profile', type: 'selectTenant' }
            );
        },
        [profile, publish, selectedUseCase]
    );

    const handleClick = useDoubleClick(
        (event, useCase) => {
            event.persist();
            onSubmit(event, useCase);
        },
        [onSubmit],
        (event, useCase) => {
            setSelectedUseCase(useCase);
        },
        [setSelectedUseCase],
        { timeout: 200 }
    );

    const sortedTenants = useMemo(() => user?.tenant.sort(ascendingByTitle) || EMPTY_ARRAY, [user?.tenant]);

    // prettier-ignore
    return ce(Centered, null,
        ce(Main, { 'data-testid': 'tenantSelect-content' },
            ce(Card, null,
                ce(Card.Header, { style: { justifyContent: 'center' } },
                    ce(Header, { name: 'card-header-title', style: { margin: 0 } }, 'Select an Application')
                ),
                ce(FullScreenBody, null,
                    sortedTenants.map(tenant =>
                        ce(TenantItem, {
                            key: tenant['identity:tenant']._id,
                            onClick: handleClick,
                            selectedUseCase,
                            startCollapsed: user.tenant.length > 3,
                            tenant
                        })
                    )
                ),
                ce(Card.Footer, { style: { justifyContent: 'flex-end' } },
                    ce(Button, { onClick: onCancel, value: 'CANCEL', buttonStyle: 'primary' }),
                    ce(Button, { onClick: onSubmit, value: 'OK', disabled: !selectedUseCase, buttonStyle: 'success' })
                )
            )
        )
    );
};

const ascendingByTitle = (a, b) => {
    const valueA = a?.['identity:tenant']?.title || '';
    const valueB = b?.['identity:tenant']?.title || '';
    if (valueA > valueB) {
        return 1;
    }
    if (valueA < valueB) {
        return -1;
    }
    return 0;
};

export default TenantSelection;
