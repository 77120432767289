import { createElement as rc, useMemo } from 'react';
import { View, styled, h3, h2, Text, Icon, fromTheme } from 'lib_ui-primitives';
import Toggle from '../../Toggle';
import { ACCESS_STATE, stateAsIcon } from '../AccessMenu/ACCESS_STATE';

const InstructionsContainer = styled(View).attrs({ name: 'Instructions' })`
    flex-direction: column;
    flex-grow: 1;
    padding: 0 10px 10px 10px;
    background-color: ${fromTheme('backgroundColor')};
`;

const Header = styled(h2)`
    margin-block-start: 0px;
`;

const MediumText = styled(Text)`
    font-size: 14px;
`;

const LeftToRight = styled(View)`
    flex-direction: row;
    gap: 12px;
    margin-bottom: 12px;
    align-items: center;
`;
const ToggleHeader = styled(View)`
    flex-grow: 0;
    margin-left: 30px;
    margin-top: -20px;
`;

export default function Instructions(props) {
    const { hNode } = props;

    const toggleProps = useMemo(() => {
        return {
            ...props,
            hNode: {
                ...props.hNode,
                id: (props?.hNode?.id ?? props?.id) + 'defaultAccess',
                propertyPath: hNode.propertyName,
                propertyName: hNode.defaultAccessPropertyName || 'defaultAccess',
                defaultValue: true,
                title: '',
                toggleValues: ['Disabled', 'Enabled'],
                hNodeType: 'Toggle'
            }
        };
    }, [hNode.defaultAccessPropertyName, hNode.propertyName, props]);

    return rc(
        InstructionsContainer,
        {},
        rc(Header, {}, 'Overview'),
        rc(
            MediumText,
            {},
            `Enable or disable this role's access to pages in the menu, 
            specific actions within each page, or viewership on different sized screens (desktop vs mobile).`
        ),
        rc(
            MediumText,
            {},
            `If updating a role that is already assigned to a user, the user should log out 
            and log back in again while online for the updates to take effect`
        ),
        rc(h3, {}, 'Grant Automatic Access to New Features'),
        rc(
            MediumText,
            {},
            `If enabled, any new features and menu items will automatically be added to the user role. 
            To remove the new features, manually update the user role.`
        ),
        rc(
            MediumText,
            {},
            'If Automatic Access is disabled, manually update the role to grant access to new features.'
        ),
        rc(ToggleHeader, null, rc(Toggle, toggleProps)),
        rc(h3, {}, 'Legend'),
        rc(
            LeftToRight,
            null,
            rc(Icon, { color: 'transparent' }, stateAsIcon(ACCESS_STATE.ENABLED)),
            rc(
                MediumText,
                {},
                `Available to the user on DESKTOP/TABLETS, as well as on MOBILE devices
                like consumer smartphones and rugged enterprise devices.`
            )
        ),
        rc(
            LeftToRight,
            null,
            rc(Icon, { color: 'transparent' }, stateAsIcon(ACCESS_STATE.DISABLED)),
            rc(MediumText, {}, 'Not available to the user. The menu entry will not be displayed.')
        ),
        rc(
            LeftToRight,
            null,
            rc(Icon, { color: 'transparent' }, stateAsIcon(ACCESS_STATE.DESKTOP_ONLY)),
            rc(
                MediumText,
                {},
                `Available to the user on DESKTOP and TABLET sized browsers only. 
                The menu entry will not be displayed on mobile devices.*`
            )
        ),
        rc(
            LeftToRight,
            null,
            rc(Icon, { color: 'transparent' }, stateAsIcon(ACCESS_STATE.MOBILE_ONLY)),
            rc(
                MediumText,
                {},
                `Available to the user on MOBILE devices like a smartphone or an enterprise mobile device only.
                The menu entry will not be displayed on the desktop or on tablets.*`
            )
        ),
        rc(
            MediumText,
            {},
            `* Some screens and functionality are designed specifically for mobile devices,
             or require desktop/tablet sized browsers. In those cases, configuration is limited to that option,
             or to not show the menu entry at all.`
        )
    );
}
