import { createElement as rc, useState, useCallback } from 'react';
import { Modal, Text, TextInput, Button, Card, h1 } from 'lib_ui-primitives';
import PropTypes from 'prop-types';
import { globalConfig } from 'lib_ui-services';

ConfigEntry.propTypes = {
    onConfigChange: PropTypes.func
};
export default function ConfigEntry(props) {
    const { configVisible, closeConfig } = props;
    const [hostname, setHostname] = useState(globalConfig().hostname);

    const update = useCallback(() => {
        globalConfig().overrideHostname(hostname);
        closeConfig();
    }, [closeConfig, hostname]);

    // prettier-ignore
    return rc(Modal, { visible: configVisible },
        rc(Card, null,
            rc(Card.Header, null, rc(h1, null, 'Configuration')),
            rc(Card.Body, null,
                rc(Text, null, 'Host name'),
                rc(TextInput, {
                    placeholder: '(e.g. dev.sstid.com, localhost or 192.168.12.103)',
                    value: hostname,
                    onChange: setHostname,
                    sequence: 100
                })
            ),
            rc(Card.Footer, null,
                rc(Button, { onClick: update, value: 'OK', color: 'success' }),
                rc(Button, { onClick: closeConfig, value: 'Cancel', })
            )
        )
    );
}
