import { globalConfig, socket } from 'lib_ui-services';
import logging from '@sstdev/lib_logging';
import lodash from 'lodash';
const { throttle } = lodash;

const RESYNC_THROTTLE_MILS = 60000;

export const _private = {
    socket,
    resync: () => {
        /*placeholder for code clarity (see below for helper method)*/
    }
};
export default {
    verb: 'doingUpdate',
    namespace: 'application',
    relation: 'network',
    description: 'Trigger Reconnect actions upon network change',
    //this is the actual logic:
    logic: doingUpdate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingUpdate({ data, dispatch, log }) {
    if (data == null) return;
    const { isOnline, isConnected, isServerReachable, type, effectiveType } = data;
    if (isConnected) {
        log.debug(
            `Network connected over ${type} with speed ${effectiveType}. Server is ${
                !isServerReachable ? 'not ' : ''
            } reachable.`
        );
        if (isOnline) {
            _private.resync(dispatch, log);
        } else {
            //this will display on the web whenever the socket connection drops while the wifi remains
            //which includes when you log out. can't have that.
            //on native it will work fine, only when the internet becomes unreachable, while the wifi is connected
            // eslint-disable-next-line no-undef
            if (__SST_REACT_NATIVE__) {
                const message = `Network connected${
                    type === 'unknown' ? '' : ' over ' + type
                } with speed equivalent to ${effectiveType}, but server is not reachable.`;
                dispatch(
                    {
                        isError: true,
                        message,
                        timeout: globalConfig().notificationTimeout
                    },
                    { verb: 'pop', namespace: 'application', relation: 'notification' }
                );
                logging.debug('[RE] ' + message);
            }
        }
    } else {
        const message = 'Network connection lost';
        log.debug(message);
        //this will display any time wifi is lost
        dispatch(
            {
                isError: true,
                message,
                timeout: globalConfig().notificationTimeout
            },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
    }
}

// If network flickers on and off, don't resync every time.
_private.resync = throttle(
    async (dispatch, log) => {
        //reconnect sockets
        _private.socket.reconnect();

        //resync
        log.debug('[RE] Initiating full sync');

        // This is super annoying when you are developing, so we only do it in production
        // eslint-disable-next-line no-undef
        if (__PRODUCTION__) {
            await dispatch(
                {},
                { verb: 'update', namespace: 'application', relation: 'useCase', type: 'syncAllDataToLocal' },
                false
            );
        }
    },
    RESYNC_THROTTLE_MILS,
    { leading: true, trailing: false }
);
