export const standardColumnDefinition = [
    { props: { hNode: { hNodeType: 'Barcode', propertyName: 'title' } } },
    //Description, Room Name, or First Name
    { props: { hNode: { propertyName: 'description' } } },
    //SerialNo, BUilding Name or Last Name
    { props: { hNode: { propertyName: 'extra1' } } },
    //department or Job Title
    { props: { hNode: { propertyName: 'extra2' } } },
    //Item Only:
    //Account
    { props: { hNode: { propertyName: 'extra3' } } },
    //Item Type
    { props: { hNode: { propertyName: 'extra4' } } },
    //Item Model
    { props: { hNode: { propertyName: 'extra5' } } }
];
/**
 * @type {Object} LabelSize
 * @property {number} width `2` - Width of label in inches
 * @property {number} height `1` - Height of label in inches
 */
export const defaultSize = { width: 2, height: 1 };

export const selectATemplate = [
    {
        isError: true,
        fixedText: 'Please select a label template'
    }
    //leaving this in as examples of non-fixed text:
    // {
    //     'print:field': {
    //         title: '2, Description, if available'
    //     }
    // },
    // {
    //     includeHumanReadable: true
    // }
];

export const emptyDesignTemplate = [
    {
        isError: true,
        fixedText: 'Please add label content'
    }
];

export const EMPTY_ARRAY = [];
