import logging from '@sstdev/lib_logging';
import globalConfig from '../globalConfig/index';
import * as bridge from './bridgeLogger';

function configureLogging(user) {
    const config = globalConfig();
    logging.setMinLogLevel(config.minLogLevel);
    if (config.logType & logging.outputType.BRIDGE) {
        logging.setBridgeLogger(bridge);
    }
    logging.setOutputType(config.logType);
    logging.setEnvironment(config.environment);
    logging.setRelease(config.logRelease);
    logging.setTenantId(user?.activeTenantId ?? '?');
    logging.setUserId(user?._id ?? '?');
    logging.setUseCaseTitle(user?.activeUseCase?.['metaui:useCase']?.title ?? '?');
}

export default configureLogging;
